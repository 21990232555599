<template>
    <v-data-table-server
        v-model="modelValue"
        v-model:items-per-page="options.itemsPerPage"
        v-model:page="options.page"
        color="primary"
        density="compact"
        :headers="headers"
        :items="items"
        :items-length="itemsLength"
        :loading="isLoading"
        :row-props="rowProps"
        @click:row="onRowClick"
        @update:sort-by="onSort">
        <template #top>
            <v-row>
                <v-col
                    class="tw-flex tw-justify-end"
                    cols="12">
                    <v-btn
                        class="max-sm:tw-mb-1"
                        color="primary"
                        @click="isModalOpen = true">
                        <v-icon>mdi-plus</v-icon>
                        Adicionar
                    </v-btn>
                    <BatteriesModal
                        v-model="isModalOpen"
                        :battery="editedBatteries"
                        @update="onModalSubmit" />
                </v-col>
            </v-row>
        </template>
        <template #item.installation.platform="{ value }">
            {{ value || 'N/A' }}
        </template>
        <template #item.installation.ref="{ value }">
            {{ value || 'N/A' }}
        </template>
        <template #item.installation.name="{ value }">
            {{ value || 'N/A' }}
        </template>
        <template #item.capacity="{ item }"> {{ item.capacity }} {{ item.capacityUnit }} </template>
        <template #item.actions="{ item }">
            <v-icon
                v-if="!isToDelete(item)"
                class="tw-mr-4"
                color="primary"
                @click="onEdit(item)">
                mdi-pencil
            </v-icon>
            <v-icon
                color="red"
                @click.stop="onDelete(item)">
                {{ isToDelete(item) ? 'mdi-restore' : 'mdi-delete' }}
            </v-icon>
        </template>
    </v-data-table-server>
</template>

<script setup lang="ts">
    import { getBatteries } from '@/api/batteries';
    import { headers } from './headers';
    import { Battery } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import BatteriesModal from './BatteriesModal.vue';
    import _ from 'lodash';

    interface BatteryCreated extends Battery {
        createdId: number;
    }

    // receive a modelValue prop
    const modelValue = defineModel<{
        _create: BatteryCreated[];
        _update: Battery[];
        _delete: Battery[];
    }>({
        type: Object,
        default: () => ({
            _create: [],
            _update: [],
            _delete: [],
        }),
    });
    const props = defineProps<{
        installationInfoId: number;
    }>();

    const batteries = ref<Battery[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);
    const isModalOpen = ref<boolean>(false);
    const editedBatteries = ref<Battery | null>(null);

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
    });

    const nextCreatedId = computed(() => {
        // get the biggest createdId
        const createdIds = modelValue.value._create.map((item) => item.createdId);
        return createdIds.length > 0 ? Math.max(...createdIds) + 1 : 1;
    });

    const items = computed(() => {
        const copy = _.cloneDeep(batteries.value);
        // add itemsCreated to the top of the list
        copy.unshift(...modelValue.value._create);
        // update itemsUpdated
        modelValue.value._update.forEach((updated) => {
            const index = copy.findIndex((item) => item.id === updated.id);
            if (index >= 0) {
                copy[index] = _.cloneDeep(updated);
            }
        });
        return copy;
    });

    async function fetchBatteries() {
        if (!props.installationInfoId) return;
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['createdAt:desc'];

        // fetch yearlyCosts
        const { data } = await getBatteries({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters: {
                installationInfo: props.installationInfoId,
            },
            populate: ['installation'],
        });

        batteries.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onEdit(battery: Battery) {
        editedBatteries.value = battery;
        // open modal
        isModalOpen.value = true;
    }

    function onRowClick(event: MouseEvent, row: { item: Battery }) {
        onEdit(row.item);
    }

    function onDelete(battery: Battery | BatteryCreated) {
        // check if the item is in the list itemsCreated
        if ((battery as BatteryCreated).createdId) {
            const batteryCreated = battery as BatteryCreated;
            const index = modelValue.value._create.findIndex((item) => item.createdId === batteryCreated.createdId);
            if (index >= 0) {
                modelValue.value._create = modelValue.value._create.filter((item) => item.createdId !== (battery as BatteryCreated).createdId);
            }
        } else {
            // check if it is already in the list itemsDeleted
            const index = modelValue.value._delete.findIndex((item) => item.id === battery.id);
            if (index < 0) {
                modelValue.value._delete.push(battery);
            } else {
                modelValue.value._delete = modelValue.value._delete.filter((item) => item.id !== battery.id);
            }
        }
    }

    function onModalSubmit(battery: Battery | BatteryCreated) {
        if (battery.id) {
            // find the item in the list itemsUpdated
            const index = modelValue.value._update.findIndex((item) => item.id === battery.id);
            if (index >= 0) {
                modelValue.value._update[index] = battery;
            } else {
                modelValue.value._update.push(battery);
            }
        } else if ((battery as BatteryCreated).createdId) {
            const createdBattery = battery as BatteryCreated;
            // find the item in the list itemsCreated
            const index = modelValue.value._create.findIndex((item) => item.createdId === createdBattery.createdId);
            if (index >= 0) {
                modelValue.value._create[index] = _.cloneDeep(createdBattery);
            } else {
                modelValue.value._create.push(createdBattery);
            }
        } else {
            // add yearlyCost to the list itemsCreated
            modelValue.value._create.push({
                ...battery,
                createdId: nextCreatedId.value,
            });
        }

        isModalOpen.value = false;
    }

    function isToDelete(battery: Battery | BatteryCreated) {
        return modelValue.value._delete.some((deleted) => deleted.id === battery.id);
    }

    function rowProps({ item }: { item: Battery | BatteryCreated }) {
        let _class = '';

        if (modelValue.value._delete.some((deleted) => deleted.id === item.id)) {
            _class = 'tw-bg-red-100';
        } else if (_.has(item, 'createdId')) {
            _class = 'tw-bg-green-100';
        } else if (modelValue.value._update.some((updated) => updated.id === item.id)) {
            _class = 'tw-bg-primary-100';
        }

        return {
            class: _class,
        };
    }

    watch(
        isModalOpen,
        (open) => {
            if (!open) {
                editedBatteries.value = null;
            }
        },
        {
            immediate: true,
        },
    );

    watch(
        options,
        () => {
            fetchBatteries();
        },
        {
            deep: true,
        },
    );

    fetchBatteries();
</script>

<style scoped></style>
