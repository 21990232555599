/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */
import * as Sentry from '@sentry/vue';
// Plugins
import vuetify from './vuetify';
import pinia from '../store';
import router from '../router';
import i18n from './i18n';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
    if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), Sentry.captureConsoleIntegration()],
            tunnel: 'https://tunnel-sentry.iotechpis.com/tunnel',
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            trackComponents: true,
            logErrors: true,
            attachProps: true,
            attachStacktrace: true,
        });
    }
    app.use(vuetify).use(router).use(pinia).use(i18n);
}
