import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    {
        title: 'Nome',
        key: 'name',
        value: 'name',
    },
    // azimuth
    {
        title: 'Azimute (°)',
        key: 'azimuth',
        value: 'azimuth',
        align: 'right',
    },
    // declination
    {
        title: 'Declinação (°)',
        key: 'declination',
        value: 'declination',
        align: 'right',
    },
    // panelQuantity
    {
        title: 'Quantidade de Painéis',
        key: 'panelQuantity',
        value: 'panelQuantity',
        align: 'right',
    },
    // panelPower
    {
        title: 'Potência dos Painéis (W)',
        key: 'panelPower',
        value: 'panelPower',
        align: 'right',
    },
    // totalPower
    {
        title: 'Potência Total (kW)',
        key: 'totalPower',
        value: 'totalPower',
        align: 'right',
    },
    // actions
    {
        title: '',
        key: 'actions',
        value: 'actions',
        align: 'end',
        sortable: false,
    }
] as VDataTableServer['headers'];
