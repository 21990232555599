<template>
    <v-dialog
        v-model="isOpen"
        max-width="900">
        <v-card>
            <v-card-title>
                <span class="">Bateria</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                        @submit.prevent="onSubmit">
                        <v-row>
                            <!-- quantity -->
                            <v-col
                                cols="12"
                                md="6"
                                sm="5">
                                <v-number-input
                                    v-model="_battery.quantity"
                                    control-variant="split"
                                    label="Quantidade"
                                    :min="1"
                                    :rules="[rules.required]"
                                    :step="1">
                                </v-number-input>
                            </v-col>
                            <!-- capacity_unit -->
                            <v-col
                                cols="12"
                                md="6"
                                sm="7">
                                <v-number-input
                                    v-model="_battery.capacity"
                                    control-variant="split"
                                    label="Capacidade"
                                    :min="0"
                                    :rules="[rules.required]"
                                    :step="100">
                                    <template #append>
                                        <v-select
                                            v-model="_battery.capacityUnit"
                                            :items="capacityUnits"
                                            label="Unidade"
                                            min-width="87"
                                            outlined>
                                        </v-select>
                                    </template>
                                </v-number-input>
                            </v-col>
                            <v-col>
                                <InstallationsTable
                                    :model-value="_battery.installation ? [_battery.installation] : []"
                                    @update:model-value="onSelectInstallation" />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tw-mr-4"
                    variant="text"
                    @click="isOpen = false">
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="onSubmit">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { Battery, Battery_CapacityUnit, Installation } from '@/contentTypes.d';
    import { VForm } from 'vuetify/components';
    import _ from 'lodash';
    import { VNumberInput } from 'vuetify/labs/VNumberInput';
    import InstallationsTable from './InstallationsTable.vue';

    const capacityUnits = Object.values(Battery_CapacityUnit);

    const rules = useRules();

    const isOpen = defineModel({
        type: Boolean,
        default: false,
    });

    const props = defineProps<{
        battery: Battery | null;
    }>();

    const emits = defineEmits<{
        update: [battery: Battery];
    }>();

    const _battery = ref<Battery>({
        id: undefined,
        capacity: 0,
        capacityUnit: capacityUnits[0],
        quantity: 1,
        installation: null,
    });
    const form = ref<VForm | null>(null);

    const isEditing = computed(() => !!_battery.value.id);

    async function onSubmit() {
        if (!form.value) return;

        const isValid = await form.value.validate();

        if (!isValid.valid) return;

        // save battery
        emits('update', _.cloneDeep(_battery.value));

        // close modal
        isOpen.value = false;
    }

    function onSelectInstallation(installations: Installation[]) {
        if (installations.length > 0) {
            _battery.value.installation = installations[0];
        } else {
            _battery.value.installation = null;
        }
    }

    watchEffect(() => {
        if (isOpen.value) {
            if (props.battery) _battery.value = _.cloneDeep(props.battery);
            else
                _battery.value = {
                    id: undefined,
                    capacity: 0,
                    capacityUnit: capacityUnits[0],
                    quantity: 1,
                    installation: null,
                };
        }
    });
</script>

<style scoped></style>
