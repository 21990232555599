<template>
    <v-data-table-server
        v-model:items-per-page="options.itemsPerPage"
        v-model:model-value="modelValue"
        v-model:page="options.page"
        color="primary"
        :headers="installationsHeaders"
        :items="installations"
        :items-length="itemsLength"
        :loading="isLoading"
        return-object
        select-strategy="single"
        show-select
        @update:sort-by="onSort">
        <template #top>
            <v-row>
                <v-col
                    cols="12"
                    md="5">
                    <v-text-field
                        v-model="search"
                        append-inner-icon="mdi-magnify"
                        clearable
                        hide-details
                        label="Pesquisar"
                        single-line
                        @update:model-value="onSearch"></v-text-field>
                </v-col>
                <v-col
                    class="tw-flex tw-justify-start"
                    cols="12"
                    md="7">
                    <!-- message saying wich is selected -->
                    <div v-if="modelValue && modelValue.length">
                        <p class="tw-text-xs">Selecionado:</p>
                        <p class="tw-text-sm tw-font-semibold">[{{ modelValue[0].platform }}] ({{ modelValue[0].ref }}) {{ modelValue[0].name }}</p>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template #item.createdAt="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
        </template>
    </v-data-table-server>
</template>

<script setup lang="ts">
    import { getInstallations } from '@/api/installations';
    import { installationsHeaders } from '../Installations/headers';
    import { Installation } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import moment from 'moment';

    // receive a modelValue prop
    const modelValue = defineModel<Installation[]>();

    const installations = ref<Installation[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);
    const timerId = ref<number | null>(null);
    const search = ref<string>('');

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 5,
        page: 1,
        sortBy: [
            {
                key: 'createdAt',
                order: 'desc',
            },
            {
                key: 'name',
                order: 'asc',
            }
        ],
    });

    async function fetchInstallations() {
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['createdAt:desc', 'name:asc'];

        const filters = search.value
            ? {
                  $or: [
                      {
                          name: {
                              $containsi: search.value,
                          },
                      },
                      {
                          ref: {
                              $containsi: search.value,
                          },
                      },
                      {
                          platform: {
                              $containsi: search.value,
                          },
                      },
                  ],
                  battery: {
                      $null: true,
                  },
                  installationInfo: {
                      $null: true,
                  },
              }
            : {};

        // fetch clients
        const { data } = await getInstallations({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters,
        });

        installations.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onSearch() {
        if (timerId.value) {
            clearTimeout(timerId.value);
        }

        timerId.value = setTimeout(() => {
            nextTick(() => {
                timerId.value = null;
                options.value.page = 1;
                fetchInstallations();
            });
        }, 300);
    }

    watch(
        options,
        () => {
            fetchInstallations();
        },
        {
            deep: true,
        },
    );

    fetchInstallations();
</script>

<style scoped></style>
