import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    // name
    {
        title: 'Nome',
        key: 'name',
        value: 'name',
    },
    // NIF
    {
        title: 'NIF',
        key: 'nif',
        value: 'nif',
    },
    // country
    {
        title: 'País',
        key: 'country',
        value: 'country',
    },
] as VDataTableServer['headers'];
