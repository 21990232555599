import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes.d';
import axios from '@axios';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _ from 'lodash';

export const API_URL = '/installations';
export const API_REF = ContentTypesUID.Installation;

export async function getInstallations(query: APIRequestParams<'Installation'> = {}, options?: AxiosRequestConfig): AxiosPromise<APIResponseMany<'Installation'>> {
    query = _.cloneDeep(query);
    // check if query has a sort key
    if (query.sort) {
        if (Array.isArray(query.sort)) {
            query.sort.push('id:desc');
        } else {
            query.sort = [query.sort, 'id:desc'];
        }
    }

    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`, options);
}
