<template>
    <h4 class="tw-text-lg tw-font-semibold">Contratos</h4>
    <template v-if="!isLoadingSection">
        <v-tabs
            v-model="currentTab"
            color="primary">
            <v-tab
                v-if="currentContract"
                value="current"
                >Atual</v-tab
            >
            <v-tab value="others">Outros</v-tab>
        </v-tabs>
        <v-tabs-window v-model="currentTab">
            <v-tabs-window-item value="current">
                <ContractsForm
                    v-if="currentContract"
                    v-model="currentContract"
                    :current-contract="currentContract"
                    :show-close="false"
                    @close="onTerminateCurrentContract"
                    @save="saveCurrentContract">
                </ContractsForm>
            </v-tabs-window-item>
            <v-tabs-window-item value="others">
                <v-data-table-server
                    v-if="!isModalOpen"
                    v-model:items-per-page="options.itemsPerPage"
                    v-model:page="options.page"
                    color="primary"
                    density="compact"
                    :headers="headers"
                    :items="items"
                    :items-length="itemsLength"
                    :loading="isLoading"
                    :row-props="rowProps"
                    @click:row="onRowClick"
                    @update:sort-by="onSort">
                    <template #top>
                        <v-row>
                            <v-col
                                class="tw-flex"
                                cols="12">
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="max-sm:tw-mb-1"
                                    color="primary"
                                    @click="addContract">
                                    <v-icon>mdi-plus</v-icon>
                                    Adicionar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template #item.contractStartDate="{ value }">
                        {{ value ? moment(value).format('DD/MM/YYYY') : 'N/A' }}
                    </template>
                    <template #item.contractEndDate="{ value }">
                        {{ value ? moment(value).format('DD/MM/YYYY') : 'N/A' }}
                    </template>
                    <template #item.actions="{ item }">
                        <v-icon
                            v-if="!isToDelete(item)"
                            class="tw-mr-4"
                            color="primary"
                            @click="onEdit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            color="red"
                            @click.stop="onDelete(item)">
                            {{ isToDelete(item) ? 'mdi-restore' : 'mdi-delete' }}
                        </v-icon>
                    </template>
                </v-data-table-server>
                <ContractsForm
                    v-else
                    v-model="editedItem"
                    :current-contract="currentContract"
                    show-close
                    @close="onModalSubmit"
                    @save="saveEditedContract">
                </ContractsForm>
            </v-tabs-window-item>
        </v-tabs-window>
    </template>
    <div v-else class="tw-flex tw-justify-center">
        <v-progress-circular
            color="primary"
            indeterminate></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import ContractsForm from './ContractsForm.vue';
    import { getContracts } from '@/api/contracts';
    import { headers } from './headers';
    import { Contract } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    // import PanelAreasModal from './PanelAreasModal.vue';
    import moment from 'moment';
    import _ from 'lodash';

    interface ContractCreated extends Contract {
        createdId: number;
    }

    // receive a modelValue prop
    const modelValue = defineModel<{
        _create: ContractCreated[];
        _update: Contract[];
        _delete: Contract[];
    }>({
        default: {
            _create: [],
            _update: [],
            _delete: [],
        },
    });

    const props = defineProps<{
        installationInfoId: number;
    }>();

    // #region data
    const currentTab = ref<string>('current');
    const contracts = ref<Contract[]>([]);
    const itemsLength = ref<number>(0);
    const isLoadingSection = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const isModalOpen = ref<boolean>(false);
    const editedItem = ref<Contract | null>(null);
    const currentContract = ref<Contract | undefined>(undefined);

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
    });

    // #endregion

    // #region computed

    const nextCreatedId = computed(() => {
        // get the biggest createdId
        const createdIds = modelValue.value?._create.map((item) => item.createdId) as number[];
        return createdIds.length > 0 ? Math.max(...createdIds) + 1 : 1;
    });

    const items = computed(() => {
        const copy = _.cloneDeep(contracts.value);
        // add itemsCreated to the top of the list
        copy.unshift(...modelValue.value?._create);
        // update itemsUpdated
        modelValue.value?._update.forEach((updated) => {
            const index = copy.findIndex((item) => item.id === updated.id);
            if (index >= 0) {
                copy[index] = _.cloneDeep(updated);
            }
        });
        return copy;
    });

    // #endregion

    // #region methods

    async function fetchItems() {
        if (!props.installationInfoId) return;
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['contractStartDate:desc'];

        // fetch panelAreas
        const { data } = await getContracts({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters: {
                installationInfo: props.installationInfoId,
                ...(currentContract.value?.id && {
                    id: {
                        $ne: currentContract.value.id,
                    },
                }),
            },
        });

        contracts.value = data.data.map((item: Contract) => {
            return {
                ...item,
                contractStartDate: item.contractStartDate ? moment(item.contractStartDate).toDate() : null,
                contractEndDate: item.contractEndDate ? moment(item.contractEndDate).toDate() : null,
                yearlyCosts: {
                    _create: [],
                    _update: [],
                    _delete: [],
                },
            };
        });

        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    async function fetchCurrentContract() {
        if (!props.installationInfoId) return;

        const { data } = await getContracts({
            pagination: {
                page: 1,
                pageSize: 1,
            },
            filters: {
                installationInfo: props.installationInfoId,
                contractEndDate: {
                    $gte: moment().format('YYYY-MM-DD'),
                },
                contractStartDate: {
                    $lte: moment().format('YYYY-MM-DD'),
                },
            },
        });

        if (data.data.length) {
            currentContract.value = {
                ...data.data[0],
                contractStartDate: data.data[0].contractStartDate ? moment(data.data[0].contractStartDate).toDate() : null,
                contractEndDate: data.data[0].contractEndDate ? moment(data.data[0].contractEndDate).toDate() : null,
                yearlyCosts: {
                    _create: [],
                    _update: [],
                    _delete: [],
                },
            };
            currentTab.value = 'current';
        } else {
            currentContract.value = undefined;
            currentTab.value = 'others';
        }
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onEdit(item: Contract) {
        editedItem.value = _.cloneDeep(item);

        // open modal
        isModalOpen.value = true;
    }

    function saveCurrentContract(contract: Contract) {
        // find the item in the list itemsUpdated
        const index = modelValue.value?._update.findIndex((item) => item.id === contract.id);
        if (index >= 0) {
            modelValue.value._update[index] = _.cloneDeep(contract);
        } else {
            modelValue.value?._update.push(_.cloneDeep(contract));
        }
    }

    function onDelete(item: Contract | ContractCreated) {
        // check if the item is in the list itemsCreated
        if ((item as ContractCreated).createdId) {
            const itemCreated = item as ContractCreated;
            const index = modelValue.value?._create.findIndex((i) => i.createdId === itemCreated.createdId);
            if (index >= 0) {
                modelValue.value._create = modelValue.value?._create.filter((i) => i.createdId !== (item as ContractCreated).createdId);
            }
        } else {
            // check if it is already in the list itemsDeleted
            const index = modelValue.value?._delete.findIndex((i) => i.id === item.id);
            if (index < 0) {
                modelValue.value?._delete.push(item);
            } else {
                modelValue.value._delete = modelValue.value?._delete.filter((i) => i.id !== item.id);
            }
        }
    }

    function onModalSubmit() {
        // saveEditedContract();
        isModalOpen.value = false;
    }

    async function saveEditedContract() {
        await nextTick();

        const item = editedItem.value as Contract | ContractCreated;

        // autoconsumption
        if (!item.autoconsumption && item.autoconsumption !== 0) {
            item.autoconsumption = null;
        }

        // contractTime
        if (!item.contractTime && item.contractTime !== 0) {
            item.contractTime = null;
        }

        // kWpAnualYield
        if (!item.kWpAnualYield && item.kWpAnualYield !== 0) {
            item.kWpAnualYield = null;
        }

        // realInstalledPower
        if (!item.realInstalledPower && item.realInstalledPower !== 0) {
            item.realInstalledPower = null;
        }

        // totalEnergy
        if (!item.totalEnergy && item.totalEnergy !== 0) {
            item.totalEnergy = null;
        }

        // totalAutoconsumption
        if (!item.totalAutoconsumption && item.totalAutoconsumption !== 0) {
            item.totalAutoconsumption = null;
        }

        if (item.id) {
            const originalContract = contracts.value.find((contract) => contract.id === item.id);
            if (!_.isEqual(originalContract, item)) {
                // find the item in the list itemsUpdated
                const index = modelValue.value?._update.findIndex((i) => i.id === item.id);
                if (index >= 0) {
                    modelValue.value._update[index] = _.cloneDeep(item);
                } else {
                    modelValue.value?._update.push(item);
                }
            }
        } else if ((item as ContractCreated).createdId) {
            const createdContract = item as ContractCreated;
            // find the item in the list itemsCreated
            const index = modelValue.value?._create.findIndex((item) => item.createdId === createdContract.createdId);
            if (index >= 0) {
                modelValue.value._create[index] = _.cloneDeep(createdContract);
            } else {
                modelValue.value?._create.push(_.cloneDeep(createdContract));
            }
        } else {
            const nextId = nextCreatedId.value;
            // @ts-ignore
            editedItem.value.createdId = nextId;
            // add item to the list itemsCreated
            modelValue.value?._create.push({
                ...item,
                createdId: nextId,
            });
        }
    }

    function isToDelete(item: Contract | ContractCreated) {
        return modelValue.value._delete.some((deleted) => deleted.id === item.id);
    }

    function rowProps({ item }: { item: Contract | ContractCreated }) {
        let _class = '';

        if (modelValue.value._delete.some((deleted) => deleted.id === item.id)) {
            _class = 'tw-bg-red-100';
        } else if (_.has(item, 'createdId')) {
            _class = 'tw-bg-green-100';
        } else if (modelValue.value._update.some((updated) => updated.id === item.id)) {
            _class = 'tw-bg-primary-100';
        }

        return {
            class: _class,
        };
    }

    function addContract() {
        editedItem.value = {
            contractStartDate: null,
            contractTime: 0,
            contractEndDate: null,
            kWpAnualYield: null,
            realInstalledPower: null,
            autoconsumption: null,
            totalEnergy: null,
            totalAutoconsumption: null,
            businessModel: undefined,
            businessType: undefined,
            currency: undefined,
            incomeType: undefined,
            yearlyCosts: {
                _create: [],
                _update: [],
                _delete: [],
            },
        };
        isModalOpen.value = true;
    }

    function onRowClick(event: MouseEvent, row: { item: Contract }) {
        onEdit(row.item);
    }

    function onTerminateCurrentContract() {
        if (!currentContract.value) return;

        const contract = _.cloneDeep(currentContract.value);
        contract.contractEndDate = moment().toDate();

        saveCurrentContract(contract);

        currentContract.value = undefined;

        fetchItems();

        currentTab.value = 'others';
    }

    // #endregion

    // #region watch

    watch(
        isModalOpen,
        (open) => {
            if (!open) {
                editedItem.value = null;
            }
        },
        {
            immediate: true,
        },
    );

    watch(
        options,
        () => {
            fetchItems();
        },
        {
            deep: true,
        },
    );

    // #endregion

    async function init() {
        isLoadingSection.value = true;
        await fetchCurrentContract();
        await fetchItems();
        isLoadingSection.value = false;
    }

    init();
</script>

<style scoped></style>
