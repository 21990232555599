<template>
    <v-data-table-server
        v-model:items-per-page="options.itemsPerPage"
        v-model:page="options.page"
        color="primary"
        density="compact"
        :headers="headers"
        :items="items"
        :items-length="itemsLength"
        :loading="isLoading"
        :row-props="rowProps"
        @update:sort-by="onSort">
        <template #top>
            <v-row>
                <v-col
                    class="tw-flex"
                    cols="12">
                    <h3 class="tw-text-base tw-font-semibold">Preços</h3>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="max-sm:tw-mb-1"
                        color="primary"
                        @click="isModalOpen = true">
                        <v-icon>mdi-plus</v-icon>
                        Adicionar
                    </v-btn>
                    <YearlyCostsModal
                        v-model="isModalOpen"
                        :contract="props.contract"
                        :yearly-cost="editedYearlyCost"
                        @update="onModalSubmit" />
                </v-col>
            </v-row>
        </template>
        <template #item.startDate="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <template #item.endDate="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <!-- <template #item.fixedPrice="{ value }">
            {{ parseFloat(value).toFixed(2) }}
        </template> -->
        <template #item.actions="{ item }">
            <v-icon
                v-if="!isToDelete(item)"
                class="tw-mr-4"
                color="primary"
                @click="onEdit(item)">
                mdi-pencil
            </v-icon>
            <v-icon
                color="red"
                @click="onDelete(item)">
                {{ isToDelete(item) ? 'mdi-restore' : 'mdi-delete' }}
            </v-icon>
        </template>
    </v-data-table-server>
</template>

<script setup lang="ts">
    import { getYearlyCosts } from '@/api/yearlyCosts';
    import { headers } from './headers';
    import { Contract, YearlyCost } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import YearlyCostsModal from './YearlyCostsModal.vue';
    import moment from 'moment';
    import _ from 'lodash';

    interface YearlyCostCreated extends YearlyCost {
        createdId: number;
    }

    // receive a modelValue prop
    const modelValue = defineModel<{
        _create: YearlyCostCreated[];
        _update: YearlyCost[];
        _delete: YearlyCost[];
    }>({
        type: Object,
        default: {
            _create: [],
            _update: [],
            _delete: [],
        },
    });
    const props = defineProps<{
        contract: Contract;
    }>();

    const yearlyCosts = ref<YearlyCost[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);
    const isModalOpen = ref<boolean>(false);
    const editedYearlyCost = ref<YearlyCost | null>(null);

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
    });

    const nextCreatedId = computed(() => {
        // get the biggest createdId
        const createdIds = modelValue.value._create.map((item) => item.createdId);
        return createdIds.length > 0 ? Math.max(...createdIds) + 1 : 1;
    });

    const items = computed(() => {
        const copy = _.cloneDeep(yearlyCosts.value);
        // add itemsCreated to the top of the list
        copy.unshift(...modelValue.value._create);
        // update itemsUpdated
        modelValue.value._update.forEach((updated) => {
            const index = copy.findIndex((item) => item.id === updated.id);
            if (index >= 0) {
                copy[index] = _.cloneDeep(updated);
            }
        });
        return copy;
    });

    const contractId = computed(() => {
        return props.contract?.id;
    });

    async function fetchYearlyCosts() {
        if (!contractId.value) return;
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['startDate:desc'];

        // fetch yearlyCosts
        const { data } = await getYearlyCosts({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters: {
                contract: contractId.value,
            },
        });

        yearlyCosts.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onEdit(yearlyCost: YearlyCost) {
        editedYearlyCost.value = yearlyCost;
        // open modal
        isModalOpen.value = true;
    }

    function onDelete(yearlyCost: YearlyCost | YearlyCostCreated) {
        // check if the item is in the list itemsCreated
        if ((yearlyCost as YearlyCostCreated).createdId) {
            const yearlyCostCreated = yearlyCost as YearlyCostCreated;
            const index = modelValue.value._create.findIndex((item) => item.createdId === yearlyCostCreated.createdId);
            if (index >= 0) {
                modelValue.value._create = modelValue.value._create.filter((item) => item.createdId !== (yearlyCost as YearlyCostCreated).createdId);
            }
        } else {
            // check if it is already in the list itemsDeleted
            const index = modelValue.value._delete.findIndex((item) => item.id === yearlyCost.id);
            if (index < 0) {
                modelValue.value._delete.push(yearlyCost);
            } else {
                modelValue.value._delete = modelValue.value._delete.filter((item) => item.id !== yearlyCost.id);
            }
        }
    }

    function onModalSubmit(yearlyCost: YearlyCost | YearlyCostCreated) {
        if (yearlyCost.id) {
            // find the item in the list itemsUpdated
            const index = modelValue.value._update.findIndex((item) => item.id === yearlyCost.id);
            if (index >= 0) {
                modelValue.value._update[index] = yearlyCost;
            } else {
                modelValue.value._update.push(yearlyCost);
            }
        } else if ((yearlyCost as YearlyCostCreated).createdId) {
            const createdYearlyCost = yearlyCost as YearlyCostCreated;
            // find the item in the list itemsCreated
            const index = modelValue.value._create.findIndex((item) => item.createdId === createdYearlyCost.createdId);
            if (index >= 0) {
                modelValue.value._create[index] = _.cloneDeep(createdYearlyCost);
            } else {
                modelValue.value._create.push(createdYearlyCost);
            }
        } else {
            // add yearlyCost to the list itemsCreated
            modelValue.value._create.push({
                ...yearlyCost,
                createdId: nextCreatedId.value,
            });
        }

        isModalOpen.value = false;
    }

    function isToDelete(yearlyCost: YearlyCost | YearlyCostCreated) {
        return modelValue.value._delete.some((deleted) => deleted.id === yearlyCost.id);
    }

    function rowProps({ item }: { item: YearlyCost | YearlyCostCreated }) {
        let _class = '';

        if (modelValue.value._delete.some((deleted) => deleted.id === item.id)) {
            _class = 'tw-bg-red-100';
        } else if (_.has(item, 'createdId')) {
            _class = 'tw-bg-green-100';
        } else if (modelValue.value._update.some((updated) => updated.id === item.id)) {
            _class = 'tw-bg-primary-100';
        }

        return {
            class: _class,
        };
    }

    watch(
        isModalOpen,
        (open) => {
            if (!open) {
                editedYearlyCost.value = null;
            }
        },
        {
            immediate: true,
        },
    );

    watch(
        options,
        () => {
            fetchYearlyCosts();
        },
        {
            deep: true,
        },
    );

    fetchYearlyCosts();
</script>

<style scoped></style>
