import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    // // createdId
    // {
    //     title: 'createdId',
    //     key: 'createdId',
    //     value: 'createdId',
    // },
    // // id
    // {
    //     title: 'ID',
    //     key: 'id',
    //     value: 'id',
    // },
    {
        title: 'Início',
        key: 'contractStartDate',
        value: 'contractStartDate',
        align: 'right',
    },
    // contractEndDate
    {
        title: 'Fim',
        key: 'contractEndDate',
        value: 'contractEndDate',
        align: 'right',
    },
    // businessType
    {
        title: 'Tipo de negócio',
        key: 'businessType',
        value: 'businessType',
    },
    // realInstalledPower
    {
        title: 'Potência instalada real (%)',
        key: 'realInstalledPower',
        value: 'realInstalledPower',
        align: 'right',
    },
    // kWpAnualYield
    {
        title: 'Rendimento anual (kWp)',
        key: 'kWpAnualYield',
        value: 'kWpAnualYield',
        align: 'right',
    },
    // actions
    {
        title: '',
        key: 'actions',
        value: 'actions',
        align: 'end',
        sortable: false,
    }
] as VDataTableServer['headers'];
