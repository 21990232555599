<template>
    <Sidebar />
    <!-- <Navbar /> -->
    <v-main>
        <ioAlert />
        <!-- <v-btn @click="isSidebarOpen = !isSidebarOpen" class="tw-z-10" position="fixed" :location="'left top'" icon="mdi-menu" rounded=""></v-btn> -->
        <router-view />
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { useI18n } from 'vue-i18n';
    import { useLocale } from '@/composables/useLocale';
    import { getTranslations } from '@/api/translations';
    import { useAuth } from '@/composables/useAuth';
    import { useTheme } from '@/composables/useTheme';

    const i18n = useI18n({ useScope: 'global' });
    const $locale = useLocale();
    const $auth = useAuth();

    $locale.setLocale('pt');

    async function fetchTranslations() {
        const { data } = await getTranslations({
            populate: 'localizations',
        });

        i18n.mergeLocaleMessage(data.data.locale, data.data.json);

        for (const locale of data.data.localizations ?? []) {
            i18n.mergeLocaleMessage(locale.locale, locale.json);
        }
    }

    if (import.meta.env.PROD) fetchTranslations();
    $auth.checkAuth();

    useTheme();
</script>
