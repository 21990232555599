<template>
    <v-data-table-server
        v-model:items-per-page="options.itemsPerPage"
        v-model:page="options.page"
        color="primary"
        density="compact"
        :headers="headers"
        :items="items"
        :items-length="itemsLength"
        :loading="isLoading"
        :row-props="rowProps"
        @click:row="onRowClick"
        @update:sort-by="onSort">
        <template #top>
            <v-row>
                <v-col
                    class="tw-flex tw-justify-end"
                    cols="12">
                    <v-btn
                        class="max-sm:tw-mb-1"
                        color="primary"
                        @click="isModalOpen = true">
                        <v-icon>mdi-plus</v-icon>
                        Adicionar
                    </v-btn>
                    <PanelAreasModal
                        v-model="isModalOpen"
                        :item="editedItem"
                        @update="onModalSubmit" />
                </v-col>
            </v-row>
        </template>
        <template #item.startDate="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <template #item.endDate="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <!-- <template #item.fixedPrice="{ value }">
            {{ parseFloat(value).toFixed(2) }}
        </template> -->
        <template #item.totalPower="{ item }">
            {{ ((item.panelPower ?? 0) * (item.panelQuantity ?? 0)) / 1000 }}
        </template>
        <template #item.actions="{ item }">
            <v-icon
                v-if="!isToDelete(item)"
                class="tw-mr-4"
                color="primary"
                @click="onEdit(item)">
                mdi-pencil
            </v-icon>
            <v-icon
                color="red"
                @click.stop="onDelete(item)">
                {{ isToDelete(item) ? 'mdi-restore' : 'mdi-delete' }}
            </v-icon>
        </template>
    </v-data-table-server>
</template>

<script setup lang="ts">
    import { getPanelAreas } from '@/api/panelAreas';
    import { headers } from './headers';
    import { PanelArea } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import PanelAreasModal from './PanelAreasModal.vue';
    import moment from 'moment';
    import _ from 'lodash';

    interface PanelAreaCreated extends PanelArea {
        createdId: number;
    }

    // receive a modelValue prop
    const modelValue = defineModel<{
        _create: PanelAreaCreated[];
        _update: PanelArea[];
        _delete: PanelArea[];
    }>({
        default: {
            _create: [],
            _update: [],
            _delete: [],
        },
    });

    const props = defineProps<{
        installationInfoId: number;
    }>();

    const panelAreas = ref<PanelArea[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);
    const isModalOpen = ref<boolean>(false);
    const editedItem = ref<PanelArea | null>(null);
    const itemsDeleted = ref<PanelArea[]>([]);

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
    });

    const nextCreatedId = computed(() => {
        // get the biggest createdId
        const createdIds = modelValue.value?._create.map((item) => item.createdId) as number[];
        return createdIds.length > 0 ? Math.max(...createdIds) + 1 : 1;
    });

    const items = computed(() => {
        const copy = _.cloneDeep(panelAreas.value);
        // add itemsCreated to the top of the list
        copy.unshift(...modelValue.value?._create);
        // update itemsUpdated
        modelValue.value?._update.forEach((updated) => {
            const index = copy.findIndex((item) => item.id === updated.id);
            if (index >= 0) {
                copy[index] = _.cloneDeep(updated);
            }
        });
        return copy;
    });

    async function fetchItems() {
        if (!props.installationInfoId) return;
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['createdAt:desc'];

        // fetch panelAreas
        const { data } = await getPanelAreas({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters: {
                installationInfo: props.installationInfoId,
            },
        });

        panelAreas.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onEdit(item: PanelArea) {
        editedItem.value = item;
        // open modal
        isModalOpen.value = true;
    }

    function onRowClick(event: MouseEvent, row: { item: PanelArea }) {
        onEdit(row.item);
    }

    function onDelete(item: PanelArea | PanelAreaCreated) {
        // check if the item is in the list itemsCreated
        if ((item as PanelAreaCreated).createdId) {
            const itemCreated = item as PanelAreaCreated;
            const index = modelValue.value?._create.findIndex((i) => i.createdId === itemCreated.createdId);
            if (index >= 0) {
                modelValue.value._create = modelValue.value?._create.filter((i) => i.createdId !== (item as PanelAreaCreated).createdId);
            }
        } else {
            // check if it is already in the list itemsDeleted
            const index = modelValue.value?._delete.findIndex((i) => i.id === item.id);
            if (index < 0) {
                modelValue.value?._delete.push(item);
            } else {
                modelValue.value._delete = modelValue.value?._delete.filter((i) => i.id !== item.id);
            }
        }
    }

    function onModalSubmit(item: PanelArea | PanelAreaCreated) {
        if (item.id) {
            // find the item in the list itemsUpdated
            const index = modelValue.value?._update.findIndex((i) => i.id === item.id);
            if (index >= 0) {
                modelValue.value._update[index] = item;
            } else {
                modelValue.value?._update.push(item);
            }
        } else if ((item as PanelAreaCreated).createdId) {
            const createdPanelArea = item as PanelAreaCreated;
            // find the item in the list itemsCreated
            const index = modelValue.value?._create.findIndex((item) => item.createdId === createdPanelArea.createdId);
            if (index >= 0) {
                modelValue.value._create[index] = _.cloneDeep(createdPanelArea);
            } else {
                modelValue.value?._create.push(createdPanelArea);
            }
        } else {
            // add item to the list itemsCreated
            modelValue.value?._create.push({
                ...item,
                createdId: nextCreatedId.value,
            });
        }

        isModalOpen.value = false;
    }

    function isToDelete(item: PanelArea | PanelAreaCreated) {
        return modelValue.value._delete.some((deleted) => deleted.id === item.id);
    }

    function rowProps({ item }: { item: PanelArea | PanelAreaCreated }) {
        let _class = '';

        if (modelValue.value._delete.some((deleted) => deleted.id === item.id)) {
            _class = 'tw-bg-red-100';
        } else if (_.has(item, 'createdId')) {
            _class = 'tw-bg-green-100';
        } else if (modelValue.value._update.some((updated) => updated.id === item.id)) {
            _class = 'tw-bg-primary-100';
        }

        return {
            class: _class,
        };
    }

    watch(
        isModalOpen,
        (open) => {
            if (!open) {
                editedItem.value = null;
            }
        },
        {
            immediate: true,
        },
    );

    watch(
        options,
        () => {
            fetchItems();
        },
        {
            deep: true,
        },
    );

    fetchItems();
</script>

<style scoped></style>
