import { createVariations } from '../../../theme';
import { APCAcontrast } from './APCA';

export type XYZ = [number, number, number];
export type LAB = [number, number, number];
export type HSV = { h: number; s: number; v: number; a?: number };
export type RGB = { r: number; g: number; b: number; a?: number };
export type HSL = { h: number; s: number; l: number; a?: number };
export type Hex = string & { __hexBrand: never };
export type Color = string | number | HSV | RGB | HSL;

function HSLtoRGB(hsla: HSL): RGB {
    return HSVtoRGB(HSLtoHSV(hsla));
}

const cssColorRe = /^(?<fn>(?:rgb|hsl)a?)\((?<values>.+)\)/;
const mappers = {
    rgb: (r: number, g: number, b: number, a?: number) => ({ r, g, b, a }),
    rgba: (r: number, g: number, b: number, a?: number) => ({ r, g, b, a }),
    hsl: (h: number, s: number, l: number, a?: number) => HSLtoRGB({ h, s, l, a }),
    hsla: (h: number, s: number, l: number, a?: number) => HSLtoRGB({ h, s, l, a }),
    hsv: (h: number, s: number, v: number, a?: number) => HSVtoRGB({ h, s, v, a }),
    hsva: (h: number, s: number, v: number, a?: number) => HSVtoRGB({ h, s, v, a }),
};

function has<T extends string>(obj: object, key: T[]): obj is Record<T, unknown> {
    return key.every((k) => obj.hasOwnProperty(k));
}

function HSVtoRGB(hsva: HSV): RGB {
    const { h, s, v, a } = hsva;
    const f = (n: number) => {
        const k = (n + h / 60) % 6;
        return v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
    };

    const rgb = [f(5), f(3), f(1)].map((v) => Math.round(v * 255));

    return { r: rgb[0], g: rgb[1], b: rgb[2], a };
}

function HSLtoHSV(hsl: HSL): HSV {
    const { h, s, l, a } = hsl;

    const v = l + s * Math.min(l, 1 - l);

    const sprime = v === 0 ? 0 : 2 - (2 * l) / v;

    return { h, s: sprime, v, a };
}

function parseColor(color: Color): RGB {
    if (typeof color === 'number') {
        if (isNaN(color) || color < 0 || color > 0xffffff) {
            // int can't have opacity
            console.warn(`'${color}' is not a valid hex color`);
        }

        return {
            r: (color & 0xff0000) >> 16,
            g: (color & 0xff00) >> 8,
            b: color & 0xff,
        };
    } else if (typeof color === 'string' && cssColorRe.test(color)) {
        const { groups } = color.match(cssColorRe)!;
        const { fn, values } = groups as { fn: keyof typeof mappers; values: string };
        const realValues = values.split(/,\s*/).map((v) => {
            if (v.endsWith('%') && ['hsl', 'hsla', 'hsv', 'hsva'].includes(fn)) {
                return parseFloat(v) / 100;
            } else {
                return parseFloat(v);
            }
        }) as [number, number, number, number?];

        return mappers[fn](...realValues);
    } else if (typeof color === 'string') {
        let hex = color.startsWith('#') ? color.slice(1) : color;

        if ([3, 4].includes(hex.length)) {
            hex = hex
                .split('')
                .map((char) => char + char)
                .join('');
        } else if (![6, 8].includes(hex.length)) {
            console.warn(`'${color}' is not a valid hex(a) color`);
        }

        const int = parseInt(hex, 16);
        if (isNaN(int) || int < 0 || int > 0xffffffff) {
            console.warn(`'${color}' is not a valid hex(a) color`);
        }

        return HexToRGB(hex as Hex);
    } else if (typeof color === 'object') {
        if (has(color, ['r', 'g', 'b'])) {
            return color;
        } else if (has(color, ['h', 's', 'l'])) {
            return HSVtoRGB(HSLtoHSV(color));
        } else if (has(color, ['h', 's', 'v'])) {
            return HSVtoRGB(color);
        }
    }

    throw new TypeError(`Invalid color: ${color == null ? color : String(color) || (color as any).constructor.name}\nExpected #hex, #hexa, rgb(), rgba(), hsl(), hsla(), object or number`);
}

function chunk(str: string, size = 1) {
    const chunked: string[] = [];
    let index = 0;
    while (index < str.length) {
        chunked.push(str.substr(index, size));
        index += size;
    }
    return chunked;
}

function padEnd(str: string, length: number, char = '0') {
    return str + char.repeat(Math.max(0, length - str.length));
}

function parseHex(hex: string): Hex {
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }

    hex = hex.replace(/([^0-9a-f])/gi, 'F');

    if (hex.length === 3 || hex.length === 4) {
        hex = hex
            .split('')
            .map((x) => x + x)
            .join('');
    }

    if (hex.length !== 6) {
        hex = padEnd(padEnd(hex, 6), 8, 'F');
    }

    return hex as Hex;
}

function HexToRGB(hex: Hex): RGB {
    hex = parseHex(hex);
    let [r, g, b, a] = chunk(hex, 2).map((c: string) => parseInt(c, 16));
    a = a === undefined ? a : a / 255;

    return { r, g, b, a };
}

function getForeground(color: Color) {
    const blackContrast = Math.abs(APCAcontrast(parseColor(0), parseColor(color)));
    const whiteContrast = Math.abs(APCAcontrast(parseColor(0xffffff), parseColor(color)));

    // Prefer white text if both have an acceptable contrast ratio
    return whiteContrast > Math.min(blackContrast, 50) ? '#fff' : '#000';
}

export function switchColor(color: string) {
    const variations = createVariations({ primary: color });
    console.log(variations);

    for (const color of Object.keys(variations)) {
        if (/^on-[a-z]/.test(color) || variations[`on-${color}`]) continue;

        const onColor = `on-${color}`;
        const colorVal = parseColor(variations[color]!);

        variations[onColor] = getForeground(colorVal);
    }

    console.log(variations);
    // parse all to rgb
    const rgbVariations = Object.fromEntries(
        Object.entries(variations).map(([key, value]) => {
            return [key, HexToRGB(value as Hex)];
        })
    );
    console.log(rgbVariations);


    const styleSheets = document.styleSheets;

    // Iterate through style sheets
    for (const sheet of Array.from(styleSheets)) {
        try {
            // Check all rules in the stylesheet
            for (const rule of Array.from(sheet.cssRules)) {
                // Find the rule for the ".v-theme--light" class
                const styleRule = rule as CSSStyleRule;
                for (const color of Object.keys(rgbVariations)) {
                    if (styleRule.style && styleRule.style.getPropertyValue(`--v-theme-${color}`)) {
                        const colorValue = rgbVariations[color];
                        // Update the variable
                        styleRule.style.setProperty(`--v-theme-${color}`, `${colorValue.r}, ${colorValue.g}, ${colorValue.b}`); // Replace with your desired color
                    }
                }
            }
        } catch (e) {
            console.warn('Unable to access stylesheet rules:', e);
        }
    }
}
