import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    {
        title: 'Data início',
        key: 'startDate',
        value: 'startDate',
        align: 'right',
    },
    // endDate
    {
        title: 'Data fim',
        key: 'endDate',
        value: 'endDate',
        align: 'right',
    },
    // fixedPrice
    {
        title: 'Valor',
        key: 'fixedPrice',
        value: 'fixedPrice',
        align: 'right',
    },
    // actions
    {
        title: '',
        key: 'actions',
        value: 'actions',
        align: 'end',
        sortable: false,
    }
] as VDataTableServer['headers'];
