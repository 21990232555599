import { useMedia } from '@/composables/useMedia';
import { ContentType } from '@/contentTypes.d';
import { defineStore } from 'pinia';
import defaultLogo from '@/assets/logo.svg';
import { switchColor } from '@/utils/theme';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: '' as string,
        user: null as ContentType<'User'> | null,
    }),
    getters: {
        isAuthenticated(): boolean {
            return !!this.token;
        },
        getToken(): string {
            return this.token;
        },
        getUser(): any {
            return this.user;
        },
        getAppLogo(): string {
            try {
                if (this.user?.entity?.logo) {
                    return useMedia(this.user?.entity?.logo, null).original || defaultLogo;
                }
                return defaultLogo;
            } catch (error) {
                return defaultLogo;
            }
        },
        getAppFavicon(): string {
            try {
                if (this.user?.entity?.favicon) {
                    return useMedia(this.user?.entity?.favicon, null).original || defaultLogo;
                }
                return defaultLogo;
            } catch (error) {
                return defaultLogo;
            }
        },
        getUserRole(): any {
            return this.user?.role;
        }
    },
    actions: {
        setToken(token: string) {
            this.token = token;
        },
        setUser(user: any) {
            this.user = user;

            // set the favicon
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
                favicon.setAttribute('href', this.getAppFavicon);
            }

            // set the document title as the entity name
            if (this.user?.entity?.name) {
                document.title = this.user.entity.name;
            }

            if (user.entity?.color) {
                localStorage.setItem('themeColor', user.entity.color);
                switchColor(user.entity.color);
            }
        },
        clearUser() {
            this.token = '';
            this.user = null;
        },
        logout() {
            this.token = '';
            this.user = null;
            // localStorage.removeItem('themeColor');
        },
    },
    persist: true,
});
