<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <h1 class="tw-mb-4 tw-text-xl tw-font-bold">Instalação</h1>
            </v-col>
            <!-- <v-col
                class="tw-flex tw-justify-end tw-gap-4"
                cols="6">
                <v-btn
                    color="primary"
                    :to="{ name: 'InstallationForm', params: { id: 'new' } }">
                    <v-icon>mdi-plus</v-icon>
                    Instalação
                </v-btn>
            </v-col> -->
        </v-row>

        <QueryBuilder
            :members="members"
            @update="onFilterUpdate" />
        <v-data-table-server
            v-model:items-per-page="options.itemsPerPage"
            v-model:page="options.page"
            :headers="columns"
            item-value="id"
            :items="installations"
            :items-length="itemsLength"
            :loading="isLoading"
            @click:row="onRowClick"
            @update:sort-by="onSort">
        </v-data-table-server>
    </v-container>
</template>

<script setup lang="ts">
    import { members } from './members';
    import { ref, watch } from 'vue';
    import { InstallationInfo } from '../../../contentTypes';
    import { getInstallationInfos } from '@/api/installationsInfos';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import QueryBuilder from '../QueryBuilder/QueryBuilder.vue';
    import { headers } from './headers';

    const $router = useRouter();

    const installations = ref<InstallationInfo[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);

    const columns = headers;

    const options = ref({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
    });

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onFilterUpdate(filters: any) {
        fetchInstallations(filters);
    }

    function onRowClick(event: MouseEvent, item: { item: InstallationInfo }) {
        // @ts-ignore
        $router.push({
            name: 'InstallationForm',
            params: { id: item.item.id },
        });
    }

    watch(
        options,
        () => {
            fetchInstallations();
        },
        {
            deep: true,
        },
    );

    async function fetchInstallations(filters: any = {}) {
        isLoading.value = true;
        // @ts-ignore
        const { data } = await getInstallationInfos({
            populate: ['installation', 'contract', 'client'],
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            ...(options.value.sortBy.length > 0 && { sort: [`${options.value.sortBy[0].key}:${options.value.sortBy[0].order}`] }),
            filters,
        });

        installations.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    // fetchInstallations();
</script>

<style scoped></style>
