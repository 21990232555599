
import axios from '@axios';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { APIRequestParams, APIResponseMany, APIResponseSingle, ContentTypesUID } from '@/contentTypes.d';

export const API_URL = '/installation-replaces';
export const API_REF = ContentTypesUID.InstallationReplace;

export async function getInstallationReplaces(query: APIRequestParams<'InstallationReplace'> = {}, options?: AxiosRequestConfig): AxiosPromise<APIResponseMany<'InstallationReplace'>> {
    query = _.cloneDeep(query);
    // check if query has a sort key
    if (query.sort) {
        if (Array.isArray(query.sort)) {
            query.sort.push('id:desc');
        } else {
            query.sort = [query.sort, 'id:desc'];
        }
    }

    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`, options);
}

export async function getInstallationReplace(id: string, query: APIRequestParams<'InstallationReplace'> = {}, options?: AxiosRequestConfig): AxiosPromise<APIResponseSingle<'InstallationReplace'>> {
    query = _.cloneDeep(query);
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`, options);
}
