import { APIRequestParams, APIResponseMany, APIResponseSingle, ContentType, ContentTypesUID } from '@/contentTypes.d';
import axios from '@axios';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _ from 'lodash';

export const API_URL = '/yearly-costs';
export const API_REF = ContentTypesUID.YearlyCost;

export async function getYearlyCosts(query: APIRequestParams<'YearlyCost'> = {}, options?: AxiosRequestConfig): AxiosPromise<APIResponseMany<'YearlyCost'>> {
    query = _.cloneDeep(query);
    // check if query has a sort key
    if (query.sort) {
        if (Array.isArray(query.sort)) {
            query.sort.push('id:desc');
        } else {
            query.sort = [query.sort, 'id:desc'];
        }
    }

    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`, options);
}


export async function createYearlyCost(data: APIRequestParams<'YearlyCost'>, options?: AxiosRequestConfig): AxiosPromise<APIResponseSingle<'YearlyCost'>> {
    return axios.post(API_URL, data, options);
}
