/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router/auto';

import Home from '@/views/Home.vue';

// INSTALLATIONS
import InstallationForm from '@/views/ContentTypes/Installations/InstallationForm.vue';
import InstallationList from '@/views/ContentTypes/Installations/InstallationList.vue';
import { useAuthStore } from '@/store/auth';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

import VFrame from '@/views/VFrame.vue';

const routes = [
    {
        path: '/',
        component: Home,
        // redirect: {
        //     name: 'Dashboard',
        //     params: {
        //         id: 1,
        //     },
        // },
        name: 'Home',
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: 'installations',
                name: 'InstallationList',
                component: InstallationList,
            },
            {
                path: 'installations/new',
                name: 'InstallationForm',
                component: InstallationForm,
            },
            {
                path: 'installations/:id',
                name: 'InstallationForm',
                component: InstallationForm,
            },
            {
                path: 'dashboard/:id',
                name: 'Dashboard',
                component: VFrame,
            },
        ],
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: ResetPassword,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.query.t) {
        localStorage.setItem('token', to.query.t as string);
    }

    const authRequired = to.matched.some((route) => route.meta.authRequired);

    if (authRequired) {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
    if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
        if (!localStorage.getItem('vuetify:dynamic-reload')) {
            console.log('Reloading page to fix dynamic import error');
            localStorage.setItem('vuetify:dynamic-reload', 'true');
            location.assign(to.fullPath);
        } else {
            console.error('Dynamic import error, reloading page did not fix it', err);
        }
    } else {
        console.error(err);
    }
});

router.isReady().then(() => {
    localStorage.removeItem('vuetify:dynamic-reload');
});

export default router;
