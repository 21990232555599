<template>
    <v-navigation-drawer
        permanent
        :rail="!isNavDrawerOpen"
        rail-width="64"
        :v-model="isNavDrawerOpen"
        width="300">
        <div class="custom-scrollbar tw-flex tw-h-full tw-flex-col tw-justify-between">
            <div
                v-if="isNavDrawerOpen"
                class="tw-absolute tw-right-0 tw-mr-1 tw-mt-4">
                <v-icon
                    class="tw-text-primary"
                    @click="isNavDrawerOpen = !isNavDrawerOpen"
                    >mdi-chevron-left</v-icon
                >
            </div>
            <div>
                <img
                    v-if="isNavDrawerOpen"
                    alt=""
                    class="tw-mx-auto tw-my-4 tw-w-3/4 tw-cursor-pointer"
                    :src="authStore.getAppLogo"
                    @click="$router.push({ name: 'Home' })" />
                <v-icon
                    v-if="!isNavDrawerOpen"
                    class="tw-m-4 tw-mx-auto tw-flex tw-text-4xl tw-text-primary"
                    @click="isNavDrawerOpen = !isNavDrawerOpen"
                    >mdi-menu</v-icon
                >
                <v-list
                    :class="{ 'custom-sidebar': !isNavDrawerOpen }"
                    density="compact"
                    nav
                    open-strategy="single">
                    <template
                        v-for="(item, i) in currentMenu"
                        :key="i">
                        <v-list-group
                            v-if="item.children"
                            :value="item.id">
                            <template #activator="{ props }">
                                <v-list-item
                                    class="tw-rounded-lg"
                                    v-bind="props"
                                    :prepend-icon="item.icon"
                                    :title="item.label"
                                    :to="item.to"></v-list-item>
                            </template>
                            <template
                                v-for="(children, j) in item?.children"
                                :key="j">
                                <v-list-item v-if="children?.children">
                                    <v-list-group :value="children.id">
                                        <template #activator="{ props }">
                                            <v-list-item
                                                class="tw-rounded-lg"
                                                v-bind="props"
                                                :prepend-icon="children.icon"
                                                :title="children.label"></v-list-item>
                                        </template>
                                        <template
                                            v-for="(grandchildren, k) in children?.children"
                                            :key="k">
                                            <v-list-item
                                                class="tw-rounded-lg"
                                                :prepend-icon="grandchildren.icon"
                                                :title="grandchildren.label"
                                                :value="grandchildren.id"
                                                @click="onItemSelected(grandchildren)"></v-list-item>
                                        </template>
                                        <v-divider
                                            v-if="!isNavDrawerOpen"
                                            class="border-opacity-50"
                                            color="white"></v-divider>
                                    </v-list-group>
                                </v-list-item>
                                <v-list-item
                                    v-else
                                    class="tw-rounded-lg"
                                    :prepend-icon="children.icon"
                                    :title="children.label"
                                    :value="children.id"
                                    @click="onItemSelected(children)">
                                </v-list-item>
                            </template>
                            <v-divider
                                v-if="!isNavDrawerOpen && currentMenu.length - 1 !== i"
                                class="border-opacity-50"
                                color="white"></v-divider>
                        </v-list-group>
                        <v-list-item
                            v-else
                            class="tw-rounded-lg"
                            :prepend-icon="item.icon"
                            :title="item.label"
                            :to="item.to"></v-list-item>
                    </template>
                </v-list>
            </div>
            <div>
                <v-list
                    bg-color="tw-text-primary"
                    class="tw-flex tw-items-center tw-justify-center tw-bg-primary">
                    <v-list-item @click="logout()">
                        <div class="tw-flex tw-text-white">
                            <v-list-item-icon>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>
                            <p
                                v-if="isNavDrawerOpen"
                                class="tw-ml-6">
                                Terminar Sessão
                            </p>
                        </div>
                    </v-list-item>
                </v-list>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { Menu } from '../types';
    import { getDashboards } from '@/api/dashboards';

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const $router = useRouter();
    const $route = useRoute();

    const isNavDrawerOpen = ref(false);

    const menus = ref<Menu[]>([
        {
            id: 'content',
            label: 'Backoffice',
            icon: 'mdi-table-of-contents',
            isOpen: false,
            children: [
                {
                    id: 'installations',
                    label: 'Instalações',
                    icon: 'mdi-solar-power-variant',
                    to: { name: 'InstallationList' },
                    isOpen: false,
                },
            ],
        },
    ]);
    const apiMenu = ref<Menu[]>([]);

    // this watch is used to handle the sidebar open/close
    watch(isNavDrawerOpen, (value) => {
        appStore.setIsSidebarOpen(value);
    });

    const currentMenu = computed(() => {
        const toReturn = [...apiMenu.value];
        if ([3, 5].includes(authStore.getUserRole?.id)) {
            toReturn.push(...menus.value);
        }
        return toReturn;
    });

    function onItemSelected(item: Menu) {
        if (item?.to) {
            $router.push(item.to);
        }
    }

    async function logout() {
        authStore.logout();

        $router.push({ name: 'Login' });
    }

    async function init() {
        const { data } = await getDashboards({
            sort: ['order:asc'],
        });

        console.log(data);

        if (data) {
            const dashboards = data as unknown as any[];
            // dashboards can have children infinite levels so we need to handle it
            function getChildren(dashboards: any[]): Menu[] {
                return dashboards.map((dashboard) => {
                    const children = dashboard.children;
                    if (children && children.length > 0) {
                        return {
                            id: `dashboard-${dashboard.id}`,
                            label: dashboard.name,
                            icon: dashboard.icon,
                            children: getChildren(children),
                        };
                    } else {
                        return {
                            id: `dashboard-${dashboard.id}`,
                            label: dashboard.name,
                            icon: dashboard.icon,
                            to: { name: 'Dashboard', params: { id: dashboard.id } },
                        };
                    }
                });
            }

            apiMenu.value = getChildren(dashboards);

            // get the first dashboard that has no children, it can be at any level
            let firstDashboard: any = null;
            function getFirstDashboard(dashboards: any[]) {
                for (const dashboard of dashboards) {
                    if (dashboard.children && dashboard.children.length > 0) {
                        getFirstDashboard(dashboard.children);
                    } else {
                        firstDashboard = dashboard;
                        break;
                    }
                }
            }

            getFirstDashboard(dashboards);

            if (firstDashboard && $route.name === 'Home') {
                $router.push({ name: 'Dashboard', params: { id: firstDashboard.id } });
            }
        }
    }

    init();
</script>

<style>
    .v-list-group__items {
        --indent-padding: 0.3125rem !important;
    }
    .custom-sidebar .v-list-group__items .v-list-item {
        max-width: 65px;
        padding-right: 9px !important;
        padding-inline-start: calc(3px + var(--indent-padding)) !important;
    }
    .custom-sidebar {
        padding-left: 11.5px !important;
        max-width: 68px !important;
        /* margin-bottom: 10px !important; */
    }
</style>
