<template>
    <v-dialog
        v-model="isOpen"
        max-width="900">
        <v-card>
            <v-card-title>
                <span class="">Painél</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                        @submit.prevent="onSubmit">
                        <v-row>
                            <!-- name -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="_panelArea.name"
                                    label="Nome"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <!-- azimuth -->
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3">
                                <v-number-input
                                    v-model="_panelArea.azimuth"
                                    control-variant="split"
                                    label="Azimute"
                                    :max="360"
                                    :min="0"
                                    :rules="[rules.required]"
                                    :step="1"
                                    suffix="°">
                                </v-number-input>
                            </v-col>
                            <!-- declination -->
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3">
                                <v-number-input
                                    v-model="_panelArea.declination"
                                    control-variant="split"
                                    label="Declinação"
                                    :max="90"
                                    :min="0"
                                    :rules="[rules.required]"
                                    :step="1"
                                    suffix="°">
                                </v-number-input>
                            </v-col>
                            <!-- panelQuantity -->
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3">
                                <v-number-input
                                    v-model="_panelArea.panelQuantity"
                                    control-variant="split"
                                    label="Quantidade de painéis"
                                    :min="1"
                                    :rules="[rules.required]"
                                    :step="1">
                                </v-number-input>
                            </v-col>
                            <!-- panelPower -->
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3">
                                <v-text-field
                                    v-model="_panelArea.panelPower"
                                    label="Potência dos painéis"
                                    :min="0"
                                    :rules="[rules.required]"
                                    :step="1"
                                    suffix="W"
                                    type="number">
                                </v-text-field>
                            </v-col>
                            <!-- panelPower -->
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3">
                                <v-text-field
                                    v-model="totalPower"
                                    label="Potência total"
                                    readonly
                                    suffix="kW"
                                    type="number">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tw-mr-4"
                    variant="text"
                    @click="isOpen = false">
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="onSubmit">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { PanelArea } from '@/contentTypes';
    import { VForm } from 'vuetify/components';
    import { VNumberInput } from 'vuetify/labs/VNumberInput';
    import _ from 'lodash';

    const rules = useRules();

    const isOpen = defineModel({
        type: Boolean,
        default: false,
    });

    const props = defineProps<{
        item: PanelArea | null;
    }>();

    const emits = defineEmits<{
        update: [item: PanelArea];
    }>();

    const _panelArea = ref<PanelArea>({
        id: undefined,
        name: '',
        azimuth: 0,
        declination: 0,
        panelQuantity: 1,
        panelPower: 0,
    });
    const form = ref<VForm | null>(null);

    const isEditing = computed(() => !!_panelArea.value.id);

    const totalPower = computed(() => ((_panelArea.value.panelQuantity ?? 0) * (_panelArea.value.panelPower ?? 0)) / 1000);

    async function onSubmit() {
        if (!form.value) return;

        const isValid = await form.value.validate();

        if (!isValid.valid) return;

        // save yearlyCost
        emits('update', _.cloneDeep(_panelArea.value));

        // close modal
        isOpen.value = false;
    }

    watchEffect(() => {
        if (isOpen.value) {
            if (props.item) _panelArea.value = _.cloneDeep(props.item);
            else
                _panelArea.value = {
                    id: undefined,
                    name: '',
                    azimuth: 0,
                    declination: 0,
                    panelQuantity: 1,
                    panelPower: 0,
                };
        }
    });
</script>

<style scoped></style>
