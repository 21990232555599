import { APIRequestParams, APIResponseMany, APIResponseSingle, ContentType, ContentTypesUID } from '@/contentTypes.d';
import axios from '@axios';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _ from 'lodash';

export const API_URL = '/metabase/dashboards';

export async function getDashboards(query: any = {}): AxiosPromise<APIResponseMany<any>> {
    query = _.cloneDeep(query);
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getDashboard(id: string, query: any = {}): AxiosPromise<APIResponseSingle<any>> {
    query = _.cloneDeep(query);
    const params = qs.stringify(query);
    return axios.get(`${API_URL}/${id}?${params}`);
}
