<template>
    <v-data-table-server
        v-model:items-per-page="options.itemsPerPage"
        v-model:page="options.page"
        color="primary"
        :headers="headers"
        :items="replaces"
        :items-length="itemsLength"
        :loading="isLoading"
        @update:sort-by="onSort">
        <template #item.replacementDate="{ item }">
            {{ moment(item.replacementDate).format('DD/MM/YYYY') }}
        </template>
        <template #item.oldInstallation="{ item }">
            <template v-if="item.oldInstallation">
                [{{ item.oldInstallation.platform }}] ({{ item.oldInstallation.ref }}) <span class="tw-font-semibold">{{ item.oldInstallation.name }}</span>
            </template>
            <template v-else>
                <span class="tw-font-semibold">N/A</span>
            </template>
        </template>
        <template #item.newInstallation="{ item }">
            <template v-if="item.newInstallation">
                [{{ item.newInstallation.platform }}] ({{ item.newInstallation.ref }}) <span class="tw-font-semibold">{{ item.newInstallation.name }}</span>
            </template>
            <template v-else>
                <span class="tw-font-semibold">N/A</span>
            </template>
        </template>
    </v-data-table-server>
</template>

<script setup lang="ts">
    import moment from 'moment';
    import { getInstallationReplaces } from '@/api/installationReplaces';
    import { headers } from './headers';
    import { InstallationReplace } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

    const props = defineProps<{
        installationInfoId: number;
    }>();

    const replaces = ref<InstallationReplace[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 5,
        page: 1,
        sortBy: [],
    });

    async function fetchInstallationReplaces() {
        if (!props.installationInfoId) return;
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['replacementDate:desc'];

        const filters = {
            installationInfo: {
                id: props.installationInfoId,
            },
        };

        // fetch clients
        const { data } = await getInstallationReplaces({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters,
            populate: ['oldInstallation', 'newInstallation'],
        });

        replaces.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    watch(
        options,
        () => {
            fetchInstallationReplaces();
        },
        {
            deep: true,
        },
    );

    fetchInstallationReplaces();
</script>

<style scoped></style>
