<template>
    <v-row class="tw-mt-0">
        <v-col
            class="tw-flex tw-items-center tw-gap-3"
            cols="12"
            md="6">
            <v-btn
                v-if="showClose"
                class="tw-rounded-md"
                color="primary"
                icon="mdi-arrow-left"
                size="small"
                variant="outlined"
                @click="onClose">
            </v-btn>
            <div v-if="!isCreating && modelValue!.contractStartDate && modelValue!.contractEndDate">
                <h3>{{ moment(modelValue!.contractStartDate).format('DD/MM/YYYY') }} a {{ moment(modelValue!.contractEndDate).format('DD/MM/YYYY') }}</h3>
            </div>
            <h3 v-else-if="isCreating">Novo contrato</h3>
        </v-col>
        <v-col
            class="tw-flex tw-items-center tw-justify-end tw-gap-3"
            cols="12"
            md="6">
            <v-alert
                v-if="isEditingCurrentContract"
                density="compact"
                max-width="250"
                type="info">
                Contrato atual
            </v-alert>
            <!-- terminar contrato botao -->
            <v-btn
                v-if="isEditingCurrentContract"
                color="red"
                variant="outlined"
                @click="terminateContract">
                Terminar contrato
            </v-btn>
        </v-col>
        <!-- modelValue!.contractStartDate -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-date-input
                v-model="modelValue!.contractStartDate"
                label="Data de início do contrato"
                :max="modelValue!.contractEndDate"
                prepend-icon=""
                prepend-inner-icon="$calendar"
                :rules="[isContractDateBefore]"></v-date-input>
        </v-col>
        <!-- modelValue!.contractEndDate -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-date-input
                v-model="modelValue!.contractEndDate"
                density="compact"
                label="Data de fim do contrato"
                :min="modelValue!.contractStartDate"
                :month="endDateMinMonth"
                prepend-icon=""
                prepend-inner-icon="$calendar"
                :rules="[isContractDateAfter]"
                :year="endDateMinYear"></v-date-input>
        </v-col>
        <!-- modelValue!.contractTime, add a sufix for months -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-text-field
                v-model.number="modelValue!.contractTime"
                label="Duração do contrato"
                suffix="meses"
                type="number"></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <!-- modelValue!.kWpAnualYield -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-text-field
                v-model.number="modelValue!.kWpAnualYield"
                label="Rendimento anual"
                suffix="kWp"
                type="number"></v-text-field>
        </v-col>
        <!-- modelValue!.realInstalledPower -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-text-field
                v-model.number="modelValue!.realInstalledPower"
                label="Potência instalada real"
                suffix="%"
                type="number"></v-text-field>
        </v-col>
        <!-- modelValue!.autoconsumption -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-text-field
                v-model.number="modelValue!.autoconsumption"
                label="Autoconsumo"
                suffix="%"
                type="number"></v-text-field>
        </v-col>
        <!-- modelValue!.totalEnergy -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-text-field
                v-model.number="modelValue!.totalEnergy"
                label="Energia total"
                suffix="kWh"
                type="number"></v-text-field>
        </v-col>
        <!-- modelValue!.totalAutoconsumption -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-text-field
                v-model.number="totalAutoConsumption"
                label="Total de autoconsumo"
                readonly
                suffix="kWh"
                type="number"></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <!-- modelValue!.businessType -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-select
                v-model="modelValue!.businessType"
                :items="contractBusinessTypes"
                label="Tipo de negócio"></v-select>
        </v-col>
        <!-- modelValue!.incomeType -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-select
                v-model="modelValue!.incomeType"
                :items="contractIncomeType"
                label="Tipo de rendimento"></v-select>
        </v-col>
        <!-- modelValue!.currency -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-select
                v-model="modelValue!.currency"
                :items="contractCurrency"
                label="Moeda"></v-select>
        </v-col>
        <!-- modelValue!.businessModel -->
        <v-col
            cols="12"
            md="4"
            sm="6"
            xl="3">
            <v-select
                v-model="modelValue!.businessModel"
                :items="contractBusinessModel"
                label="Modelo de negócio"></v-select>
        </v-col>
    </v-row>
    <v-divider class="tw-my-8"></v-divider>
    <YearlyCostsTable
        v-model="modelValue!.yearlyCosts"
        :contract="modelValue" />
</template>

<script setup lang="ts">
    import { Contract, Contract_BusinessModel, Contract_BusinessType, Contract_Currency, Contract_IncomeType } from '@/contentTypes.d';
    import { VDateInput } from 'vuetify/labs/VDateInput';
    import YearlyCostsTable from '../YearlyCosts/YearlyCostsTable.vue';
    import moment from 'moment';
    import _ from 'lodash';
    import useRules from '@/composables/rules';

    interface ContractCreated extends Contract {
        createdId: number;
    }

    const modelValue = defineModel<Contract | ContractCreated | null | undefined>();
    const emits = defineEmits(['save', 'close']);
    const props = defineProps<{
        currentContract: Contract | ContractCreated | null | undefined;
        showClose: boolean;
    }>();
    const rules = useRules();

    const contractBusinessTypes = Object.values(Contract_BusinessType);
    const contractIncomeType = Object.values(Contract_IncomeType);
    const contractCurrency = Object.values(Contract_Currency);
    const contractBusinessModel = Object.values(Contract_BusinessModel);

    const modelValueId = computed(() => modelValue.value?.id as number);

    const isCreating = computed(() => {
        return !modelValue.value?.id && !_.has(modelValue.value, 'createdId');
    });

    const isEditingCurrentContract = computed(() => {
        if (isCreating.value || _.has(modelValue.value, 'createdId')) return false;

        return modelValue.value?.id === props.currentContract?.id;
    });

    const totalAutoConsumption = computed(() => {
        if (!modelValue.value?.totalEnergy || !modelValue.value?.autoconsumption) {
            return 0;
        }

        return modelValue.value.totalEnergy * (modelValue.value.autoconsumption / 100);
    });

    const contractDuration = computed(() => {
        try {
            if (!modelValue.value?.contractStartDate || !modelValue.value?.contractEndDate) {
                return 0;
            }

            const startDate = moment(modelValue.value.contractStartDate);
            const endDate = moment(modelValue.value.contractEndDate);

            return endDate.diff(startDate, 'months');
        } catch (error) {
            return 0;
        }
    });

    const endDateMinYear = computed(() => {
        if (!modelValue.value?.contractStartDate || !!modelValue.value.contractEndDate) return undefined;
        return moment(modelValue.value.contractStartDate).year();
    });

    const endDateMinMonth = computed(() => {
        if (!modelValue.value?.contractStartDate || !!modelValue.value.contractEndDate) return undefined;
        return moment(modelValue.value.contractStartDate).month();
    });

    function isContractDateAfter() {
        if (!modelValue.value?.contractEndDate) return true;
        const res = rules.after(modelValue.value?.contractEndDate, modelValue.value?.contractStartDate);
        return typeof res === 'string' ? 'Data de fim deve ser posterior à data de início' : true;
    }

    function isContractDateBefore() {
        if (!modelValue.value?.contractStartDate) return true;
        const res = rules.before(modelValue.value?.contractStartDate, modelValue.value?.contractEndDate);
        return typeof res === 'string' ? 'Data de início deve ser anterior à data de fim' : true;
    }

    function onClose() {
        emits('close');
    }

    function terminateContract() {
        if (modelValue.value) {
            emits('close');
        }
    }

    watchEffect(() => {
        if (modelValue.value) {
            modelValue.value.contractTime = contractDuration.value;
        }
    });

    // watch the modelValue and emit the save event
    watch(
        modelValue,
        () => {
            emits('save', modelValue.value);
        },
        { deep: true },
    );
</script>

<style scoped></style>
