<template>
    <v-data-table-server
        v-model:items-per-page="options.itemsPerPage"
        v-model:model-value="modelValue"
        v-model:page="options.page"
        class="tw-mt-4"
        color="primary"
        :headers="headers"
        :items="clients"
        :items-length="itemsLength"
        :loading="isLoading"
        return-object
        select-strategy="single"
        show-select
        @update:sort-by="onSort">
        <template #top>
            <v-row>
                <v-col
                    cols="12"
                    md="3">
                    <v-text-field
                        v-model="search"
                        append-inner-icon="mdi-magnify"
                        clearable
                        hide-details
                        label="Pesquisar"
                        single-line
                        @update:model-value="onSearch"></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="5">
                    <div v-if="modelValue && modelValue.length">
                        <p class="tw-text-xs">Selecionado:</p>
                        <p class="tw-text-sm tw-font-semibold">[{{ modelValue[0].nif }}] {{ modelValue[0].name }}</p>
                    </div>
                </v-col>
                <v-col
                    class="tw-flex tw-justify-end tw-gap-4"
                    cols="12"
                    md="4">
                    <v-btn
                        v-if="props.showCancel"
                        @click="emit('cancel')">
                        Cancelar
                    </v-btn>
                    <v-btn
                        class="tw-rounded-md"
                        color="primary"
                        height="36"
                        icon="mdi-plus"
                        width="36"
                        @click="isModalOpen = true">
                    </v-btn>
                    <ClientsModal
                        v-model="isModalOpen"
                        @update="onModalSubmit" />
                </v-col>
            </v-row>
        </template>
    </v-data-table-server>
</template>

<script setup lang="ts">
    import { getClients } from '@/api/clients';
    import { headers } from './headers';
    import { Client } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import ClientsModal from '../Installations/ClientsModal.vue';

    // receive a modelValue prop
    const modelValue = defineModel<Client[]>();
    const isModalOpen = ref<boolean>(false);

    const props = defineProps<{
        showCancel: boolean;
    }>();

    const emit = defineEmits<{
        cancel: [];
    }>();

    const clients = ref<Client[]>([]);
    const itemsLength = ref<number>(0);
    const isLoading = ref<boolean>(false);
    const timerId = ref<number | null>(null);
    const search = ref<string>('');

    const options = ref<{
        itemsPerPage: number;
        page: number;
        sortBy: VDataTableServer['sortBy'];
    }>({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
    });

    async function fetchClients() {
        isLoading.value = true;

        const sort =
            options.value.sortBy.length > 0
                ? options.value.sortBy.map((sort) => {
                      return `${sort.key}:${sort.order}`;
                  })
                : ['id:asc'];

        const filters = search.value
            ? {
                  $or: [
                      {
                          name: {
                              $containsi: search.value,
                          },
                      },
                      {
                          accountNumber: {
                              $containsi: search.value,
                          },
                      },
                      {
                          nif: {
                              $containsi: search.value,
                          },
                      },
                  ],
              }
            : {};

        // fetch clients
        const { data } = await getClients({
            pagination: {
                page: options.value.page,
                pageSize: options.value.itemsPerPage,
            },
            // @ts-ignore
            sort,
            filters,
        });

        clients.value = data.data;
        itemsLength.value = data.meta.pagination.total;
        isLoading.value = false;
    }

    function onSort(sortBy: VDataTableServer['sortBy']) {
        // @ts-ignore
        options.value.sortBy = sortBy;
    }

    function onModalSubmit(item: Client) {
        modelValue.value = [item];
        isModalOpen.value = false;
    }

    function onSearch() {
        if (timerId.value) {
            clearTimeout(timerId.value);
        }

        timerId.value = setTimeout(() => {
            nextTick(() => {
                timerId.value = null;
                options.value.page = 1;
                fetchClients();
            });
        }, 300);
    }

    watch(
        options,
        () => {
            fetchClients();
        },
        {
            deep: true,
        },
    );

    fetchClients();
</script>

<style scoped></style>
