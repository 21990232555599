<template>
    <div id="map"></div>
</template>

<script setup lang="ts">
    import { Loader } from '@googlemaps/js-api-loader';

    const modelValue = defineModel<{
        lat: number;
        lng: number;
    }>({
        default: { lat: 41.550081, lng: -8.415505 },
        get(v) {
            if(!v || !v.lat || !v.lng) {
                return { lat: 41.550081, lng: -8.415505 };
            }
            return v;
        },
    });

    let map: google.maps.Map;

    const loader = new Loader({
        apiKey: import.meta.env.VITE_GMAP_API_KEY,
    });
    const MapsLibrary = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;

    onMounted(async () => {
        console.log(modelValue.value);

        map = new MapsLibrary.Map(document.getElementById('map') as HTMLElement, {
            center: modelValue.value,
            zoom: 12,
            // disableDefaultUI: true,
            gestureHandling: 'greedy',
            mapId: 'b1b1b1b1b1b1b1b1',
        });
        const marker = new AdvancedMarkerElement({
            position: modelValue.value,
            map,
            gmpDraggable: true,
        });

        // on marker drag
        marker.addListener('dragend', () => {
            const position = marker.position;
            if (position) {
                modelValue.value = {
                    lat: typeof position.lat === 'function' ? position.lat() : position.lat,
                    lng: typeof position.lng === 'function' ? position.lng() : position.lng,
                };
            }
        });
    });
</script>

<style scoped></style>
