import { IMember } from '@/types';

export const members = [
    {
        field: 'country',
        type: 'text',
    },
    {
        field: 'address',
        type: 'text',
    },
    {
        field: 'client.nif',
        type: 'text',
    },
    {
        field: 'client.name',
        type: 'text',
    },
    {
        field: 'client.postalCode',
        type: 'text',
    },
    // client.country
    {
        field: 'client.country',
        type: 'text',
    },
    // client.city
    {
        field: 'client.city',
        type: 'text',
    },
    // installation.name
    {
        field: 'installation.name',
        type: 'text',
    },
    // contract.contractStartDate
    {
        field: 'contract.contractStartDate',
        type: 'date',
    },
    // contract.contractEndDate
    {
        field: 'contract.contractEndDate',
        type: 'date',
    },
    // contract.businessType
    {
        field: 'contract.businessType',
        type: 'enum',
        values: ['PPA', 'EPC', 'OTHER'],
    },
    // contract.businessModel
    {
        field: 'contract.businessModel',
        type: 'enum',
        values: ['selfConsumption', 'produced', 'fixed'],
    },
] as IMember[];
