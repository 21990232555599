import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    {
        title: 'Plataforma',
        key: 'installation.platform',
        value: 'installation.platform',
    },
    {
        title: 'REF',
        key: 'installation.ref',
        value: 'installation.ref',
    },
    // installation.name
    {
        title: 'Nome',
        key: 'installation.name',
        value: 'installation.name',
    },
    // quantity
    {
        title: 'Quantidade',
        key: 'quantity',
        value: 'quantity',
        align: 'right',
    },
    // capacity_unit
    {
        title: 'Capacidade',
        key: 'capacity',
        value: 'capacity',
        align: 'right',
    },
    //
    {
        title: '',
        key: 'actions',
        value: 'actions',
        align: 'end',
        sortable: false,
    }
] as VDataTableServer['headers'];
