<template>
    <v-dialog
        v-model="isOpen"
        max-width="900">
        <v-card>
            <v-card-title>
                <span class="">Preço</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                        @submit.prevent="onSubmit">
                        <v-row>
                            <!-- startDate -->
                            <v-col
                                cols="12"
                                md="6"
                                xl="4">
                                <v-date-input
                                    v-model="_yearlyCost.startDate"
                                    label="Data de início"
                                    :max="maxDateForStart"
                                    :min="props.contract.contractStartDate"
                                    :month="startDateMinMonth"
                                    :rules="[rules.required, isDateBefore]"
                                    :year="startDateMinYear">
                                </v-date-input>
                            </v-col>
                            <!-- endDate -->
                            <v-col
                                cols="12"
                                md="6"
                                xl="4">
                                <v-date-input
                                    v-model="_yearlyCost.endDate"
                                    label="Data de fim"
                                    :max="props.contract.contractEndDate"
                                    :min="minDateForEnd"
                                    :month="endDateMinMonth"
                                    :rules="[rules.required, isDateAfter]"
                                    :year="endDateMinYear">
                                </v-date-input>
                            </v-col>
                            <!-- fixedPrice -->
                            <v-col
                                cols="12"
                                md="6"
                                xl="4">
                                <v-text-field
                                    v-model="_yearlyCost.fixedPrice"
                                    label="Valor"
                                    :rules="[rules.required]"
                                    type="number">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tw-mr-4"
                    variant="text"
                    @click="isOpen = false">
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="onSubmit">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { Contract, YearlyCost } from '@/contentTypes';
    import { VForm } from 'vuetify/components';
    import { VDateInput } from 'vuetify/labs/VDateInput';
    import _ from 'lodash';
    import moment from 'moment';

    const rules = useRules();

    const isOpen = defineModel({
        type: Boolean,
        default: false,
    });

    const props = defineProps<{
        yearlyCost: YearlyCost | null;
        contract: Contract;
    }>();

    const emits = defineEmits<{
        update: [yearlyCost: YearlyCost];
    }>();

    const _yearlyCost = ref<YearlyCost>({
        id: undefined,
        startDate: null,
        endDate: null,
        fixedPrice: 0,
    });
    const form = ref<VForm | null>(null);

    const startDateMinYear = computed(() => {
        if (_yearlyCost.value?.startDate) return moment(_yearlyCost.value.startDate).year();
        else if (_yearlyCost.value?.endDate) return moment(_yearlyCost.value.endDate).year();
        else if (props.contract.contractStartDate) return moment(props.contract.contractStartDate).year();
        else if (props.contract.contractEndDate) return moment(props.contract.contractEndDate).year();
        return undefined;
    });

    const startDateMinMonth = computed(() => {
        if (_yearlyCost.value?.startDate) return moment(_yearlyCost.value.startDate).month();
        else if (_yearlyCost.value?.endDate) return moment(_yearlyCost.value.endDate).month();
        else if (props.contract.contractStartDate) return moment(props.contract.contractStartDate).month();
        else if (props.contract.contractEndDate) return moment(props.contract.contractEndDate).month();
        return undefined;
    });

    const endDateMinYear = computed(() => {
        if(_yearlyCost.value?.endDate) return moment(_yearlyCost.value.endDate).year();
        else if (_yearlyCost.value.startDate) return moment(_yearlyCost.value.startDate).year();
        else if (props.contract.contractStartDate) return moment(props.contract.contractStartDate).year();
        else if (props.contract.contractEndDate) return moment(props.contract.contractEndDate).year();
        return undefined;
    });

    const endDateMinMonth = computed(() => {
        if(_yearlyCost.value?.endDate) return moment(_yearlyCost.value.endDate).month();
        else if (_yearlyCost.value.startDate) return moment(_yearlyCost.value.startDate).month();
        else if (props.contract.contractStartDate) return moment(props.contract.contractStartDate).month();
        else if (props.contract.contractEndDate) return moment(props.contract.contractEndDate).month();
        return undefined;
    });

    const maxDateForStart = computed(() => {
        if (_yearlyCost.value.endDate) return moment(_yearlyCost.value.endDate).toDate();
        else if (props.contract.contractEndDate) return moment(props.contract.contractEndDate).toDate();
        return undefined;
    });

    const minDateForEnd = computed(() => {
        if (_yearlyCost.value.startDate) return moment(_yearlyCost.value.startDate).toDate();
        else if (props.contract.contractStartDate) return moment(props.contract.contractStartDate).toDate();
        return undefined;
    });

    function isDateAfter() {
        if (!_yearlyCost.value.endDate) return true;
        const res = rules.after(_yearlyCost.value.endDate, _yearlyCost.value.startDate);
        return typeof res === 'string' ? 'Data de fim deve ser posterior à data de início' : true;
    }

    function isDateBefore() {
        if (!_yearlyCost.value.startDate) return true;
        const res = rules.before(_yearlyCost.value.startDate, _yearlyCost.value.endDate);
        return typeof res === 'string' ? 'Data de início deve ser anterior à data de fim' : true;
    }

    async function onSubmit() {
        if (!form.value) return;

        const isValid = await form.value.validate();

        if (!isValid.valid) return;
        // console.log(moment(_yearlyCost.value.startDate).format('YYYY-MM-DD'));

        // save yearlyCost
        emits('update', _.cloneDeep({ ..._yearlyCost.value, startDate: moment(_yearlyCost.value.startDate).format('YYYY-MM-DD'), endDate: moment(_yearlyCost.value.endDate).format('YYYY-MM-DD') }));

        // close modal
        isOpen.value = false;
    }

    watchEffect(() => {
        if (isOpen.value) {
            if (props.yearlyCost)
                _yearlyCost.value = _.cloneDeep({
                    ...props.yearlyCost,
                    startDate: props.yearlyCost.startDate ? new Date(props.yearlyCost.startDate) : null,
                    endDate: props.yearlyCost.endDate ? new Date(props.yearlyCost.endDate) : null,
                });
            else
                _yearlyCost.value = {
                    id: undefined,
                    startDate: null,
                    endDate: null,
                    fixedPrice: 0,
                };
        }
    });
</script>

<style scoped></style>
