<template>
    <v-dialog
        v-model="isOpen"
        max-width="900">
        <v-card>
            <v-card-title>
                <span class="">Cliente</span>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-form
                        ref="form"
                        @submit.prevent="onSubmit">
                        <v-row>
                            <!-- name -->
                            <v-col
                                cols="12"
                                lg="4"
                                md="6">
                                <v-text-field
                                    v-model="_client.name"
                                    label="Nome"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <!-- nif -->
                            <v-col
                                cols="12"
                                lg="4"
                                sm="6">
                                <v-text-field
                                    v-model="_client.nif"
                                    :error-messages="nifErrorMessages"
                                    label="NIF"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>

                            <!-- accountNumber -->
                            <!-- <v-col
                                cols="12"
                                lg="4" sm="6">
                                <v-text-field
                                    v-model="_client.accountNumber"
                                    label="Número de Conta"
                                    :rules="[]">
                                </v-text-field>
                            </v-col> -->
                            <!-- postalCode -->
                            <v-col
                                cols="12"
                                lg="4"
                                sm="6">
                                <v-text-field
                                    v-model="_client.postalCode"
                                    label="Código Postal"
                                    :rules="[]">
                                </v-text-field>
                            </v-col>
                            <!-- city -->
                            <v-col
                                cols="12"
                                lg="4"
                                sm="6">
                                <v-text-field
                                    v-model="_client.city"
                                    label="Cidade"
                                    :rules="[]">
                                </v-text-field>
                            </v-col>
                            <!-- country -->
                            <v-col
                                cols="12"
                                lg="4"
                                sm="6">
                                <v-text-field
                                    v-model="_client.country"
                                    label="País"
                                    :rules="[]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tw-mr-4"
                    variant="text"
                    @click="isOpen = false">
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="onSubmit">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { getClientByNif } from '@/api/clients';
    import useRules from '@/composables/rules';
    import { Client } from '@/contentTypes.d';
    import _ from 'lodash';
    import { VForm } from 'vuetify/components';

    const rules = useRules();

    const isOpen = defineModel({
        type: Boolean,
        default: false,
    });
    const emits = defineEmits<{
        update: [item: Client];
    }>();

    const form = ref<VForm | null>(null);
    const nifErrorMessages = ref<string[]>([]);

    const _client = ref<Client>({
        id: undefined,
        nif: '',
        name: '',
        // accountNumber: '',
        country: '',
        city: '',
        postalCode: '',
    });

    async function onSubmit() {
        if (!form.value) return;

        nifErrorMessages.value = [];

        const isValid = await form.value.validate();

        if (!isValid.valid) return;

        const { data } = await getClientByNif(_client.value.nif as string);

        if(_.get(data, 'client.id')) {
            nifErrorMessages.value = ['Já existe um cliente com este NIF'];
            return;
        }

        emits('update', _.cloneDeep(_client.value));

        // close modal
        isOpen.value = false;
    }

    watchEffect(() => {
        if (isOpen.value) {
            _client.value = {
                id: undefined,
                nif: '',
                name: '',
                // accountNumber: '',
                country: '',
                city: '',
                postalCode: '',
            };
        }
    });
</script>

<style scoped></style>
