<template>
    <v-row
        id="query-builder"
        class="tw-mb-2 tw-text-sm"
        dense>
        <v-col cols="12">
            <div class="tw-flex tw-flex-wrap tw-items-center">
                <v-autocomplete
                    v-model="toAddFilter.member"
                    auto-select-first
                    class="query-member tw-w-full tw-max-w-52"
                    density="compact"
                    item-title="text"
                    :items="_members"
                    :menu-props="menuProps"
                    return-object>
                </v-autocomplete>
                <v-autocomplete
                    v-model="toAddFilter.operator"
                    auto-select-first
                    class="query-operator tw-w-full tw-max-w-44"
                    density="compact"
                    item-title="text"
                    :items="fieldOperators"
                    :menu-props="menuProps"
                    return-object>
                </v-autocomplete>
                <v-text-field
                    v-if="toAddFilter.member && ['text', 'number'].includes(toAddFilter.member?.type)"
                    v-model="toAddFilter.value"
                    class="query-value tw-w-full tw-max-w-52"
                    dense
                    density="compact"
                    @keyup.enter="addFilter">
                </v-text-field>
                <v-date-input
                    v-else-if="toAddFilter.member && ['date'].includes(toAddFilter.member?.type)"
                    v-model="toAddFilter.value"
                    class="query-value tw-w-full tw-max-w-52"
                    dense
                    density="compact"
                    prepend-icon=""
                    @keyup.enter="addFilter">
                </v-date-input>
                <v-btn
                    class="tw-ml-2 tw-rounded-md tw-text-xl"
                    color="primary"
                    icon
                    size="small"
                    @click="addFilter">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>
        </v-col>
    </v-row>
    <v-row dense>
        <v-col cols="12">
            <v-chip
                v-for="filter in filters"
                :key="filter.id"
                class="tw-mb-2 tw-mr-2"
                closable
                color="primary"
                @click:close="removeFilter(filter.id || -1)">
                {{ filter.member?.field }} {{ filter.operator?.text }} {{ filter.value }}
            </v-chip>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { operators } from './operators';
    import { IFilter, IMember, IOperator } from '@/types';
    import { useRoute, useRouter } from 'vue-router';
    import { flatten, unflatten } from 'flat';
    import _ from 'lodash';
    import { VDateInput } from 'vuetify/lib/labs/components.mjs';
    import moment from 'moment';
    import { useI18n } from 'vue-i18n';

    const $router = useRouter();
    const $route = useRoute();
    const { t } = useI18n();

    const props = defineProps<{
        members: IMember[];
    }>();

    const emits = defineEmits(['update']);

    const filters = ref<IFilter[]>([]);

    const biggestFilterId = computed(() => {
        return filters.value.reduce((acc, filter) => {
            return filter.id && filter.id > acc ? filter.id : acc;
        }, 0);
    });

    const fieldOperators = computed(() => {
        if (!toAddFilter.value.member) return [];
        const ops = operators[toAddFilter.value.member?.type];
        return ops.map((op) => ({
            ...op,
            text: t(`operators.${op.text}`),
        }));
    });

    const _members = computed(() => {
        return props.members.map((member) => ({
            ...member,
            text: t(`members.${member.field}`),
        }));
    });

    const toAddFilter = ref<IFilter>({
        member: null,
        operator: null,
        value: null,
    });

    function addToQuery() {
        const $and = [];
        for (const filter of filters.value) {
            const query: any = {};
            if (filter.member?.field) {
                query[filter.member.field] = {
                    [`${filter.operator?.value}`]: filter.value,
                };
                $and.push(query);
            }
        }
        $router.push({
            query: flatten(
                { filters: { $and } },
                {
                    delimiter: '_',
                },
            ),
        });

        emits('update', parseToStrapiQuery(filters.value));
    }

    function parseToStrapiQuery(filters: IFilter[]) {
        const _filters = filters.reduce((acc: any, filter) => {
            if (filter.member && filter.operator) {
                const field = filter.member.field;
                let value = filter.value;
                if (filter.member.type === 'date') {
                    value = moment(value).format('YYYY-MM-DD');
                }
                const operator = filter.operator.value;

                const unflattenField = field.split('.');
                const unflattenFilters = unflattenField.reduceRight(
                    (acc, curr) => {
                        const obj: any = {}; // Add index signature
                        obj[curr] = acc;
                        return obj;
                    },
                    { [operator]: value },
                );

                Object.assign(acc, unflattenFilters);
            }
            return acc;
        }, {});

        return _filters;
    }

    function addFilter() {
        let value = toAddFilter.value.value;
        if (toAddFilter.value.member && toAddFilter.value.member.type === 'date') {
            value = moment(value).format('DD/MM/YYYY');
        }
        filters.value.push({ ...toAddFilter.value, value, id: biggestFilterId.value + 1 });
        toAddFilter.value.value = null;
        // toAddFilter.value = {
        //     member: props.members[0],
        //     operator: operators[props.members[0].type][0],
        //     value: null,
        // };
        addToQuery();
    }

    function removeFilter(id: number) {
        filters.value = filters.value.filter((filter) => filter.id !== id);
        addToQuery();
    }

    const menuProps = {
        class: 'query-builder-autocomplete',
    };

    function initQuery() {
        const currentQuery = unflatten($route.query, { delimiter: '_' });

        const $and = _.get(currentQuery, 'filters.$and', []);
        for (const q of $and) {
            const key = Object.keys(q)[0];
            const value = Object.values(q)[0];
            const member = props.members.find((m) => m.field === key);
            if (member && value) {
                const operator = operators[member.type].find((o) => o.value === Object.keys(value)[0]);
                if (operator) filters.value.push({ member, operator, value: Object.values(value)[0], id: biggestFilterId.value + 1 });
            }
        }

        // if(filters.value.length) {
        emits('update', parseToStrapiQuery(filters.value));
        // }
    }

    initQuery();

    onMounted(() => {
        toAddFilter.value.member = _members.value[0];
        toAddFilter.value.operator = fieldOperators.value[0];
    });
</script>

<style>
    #query-builder .v-field,
    .query-builder-autocomplete .v-list-item-title {
        @apply tw-text-sm;
    }

    #query-builder .query-member .v-field {
        @apply tw-rounded-r-none;
    }

    #query-builder .query-operator .v-field {
        @apply tw-rounded-none;
    }

    #query-builder .query-value .v-field {
        @apply tw-rounded-l-none;
    }
</style>
