/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { en, pt } from 'vuetify/locale';
// Composables
import { createVuetify } from 'vuetify';
import { createVariations, vuetifyColors } from '../../theme';
import type { VAlert, VAutocomplete, VBanner, VBtn, VDataTableServer, VField, VFileInput, VSelect, VSwitch, VTextarea, VTextField } from 'vuetify/lib/components/index.mjs';
import { VNumberInput } from 'vuetify/labs/components';
import _ from 'lodash';

const colors = vuetifyColors();

// check localStorage for theme color
const themeColor = localStorage.getItem('themeColor');
if (themeColor) {
    const newColors = createVariations({ primary: themeColor });
    _.merge(colors, newColors);
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    defaults: {
        VField: {
            // density: 'compact',
            hideDetails: 'auto',
            variant: 'outlined',
            bgColor: 'gray-100',
            hideSpinButtons: true,
        } as VField['$props'],
        VTextField: {
            density: 'compact',
            hideDetails: 'auto',
            variant: 'outlined',
            bgColor: 'gray-100',
            hideSpinButtons: true,
        } as VTextField['$props'],
        VDateInput: {
            density: 'compact',
            color: 'primary',
            hideDetails: 'auto',
            variant: 'outlined',
            bgColor: 'gray-100',
            prependIcon: '',
            placeholder: 'dd/mm/yyyy',
            prependInnerIcon: '$calendar',
            hideSpinButtons: true,
            hideActions: true,
        } as VTextField['$props'],
        VBtn: {
            // elevation: 1,
        } as VBtn['$props'],
        VAutocomplete: {
            // density: 'compact',
            hideDetails: 'auto',
            variant: 'outlined',
        } as VAutocomplete['$props'],
        VTextarea: {
            // density: 'compact',
            bgColor: 'gray-100',
            hideDetails: 'auto',
            autoGrow: true,
            variant: 'outlined',
        } as VTextarea['$props'],
        VFileInput: {
            variant: 'outlined',
            hideDetails: 'auto',
            bgColor: 'gray-100',
        } as VFileInput['$props'],
        VSwitch: {
            hideDetails: 'auto',
        } as VSwitch['$props'],
        VSelect: {
            density: 'compact',
            variant: 'outlined',
            hideDetails: 'auto',
        } as VSelect['$props'],
        VDataTableServer: {
            hover: true,
            itemsPerPageOptions: [10, 25, 50, 100],
            itemsLength: 0,
        } as VDataTableServer['$props'],
        VNumberInput: {
            hideDetails: 'auto',
            density: 'compact',
            variant: 'outlined',
        } as VNumberInput['$props'],
    },
    locale: {
        locale: 'pt',
        fallback: 'en',
        messages: { pt, en },
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1536,
        },
    },
    theme: {
        themes: {
            light: {
                colors
            },
        },
    },
});
