import { IMember, IOperator } from '@/types';

export const operators = {
    date: [
        {
            text: 'is',
            value: '$eq',
        },
        // $ne
        {
            text: 'isNot',
            value: '$ne',
        },
        // $null
        {
            text: 'isNull',
            value: '$null',
        },
        // $notNull
        {
            text: 'isNotNull',
            value: '$notNull',
        },
        // $gt
        {
            text: 'isAfter',
            value: '$gt',
        },
        // $gte
        {
            text: 'isAfterOrEqualTo',
            value: '$gte',
        },
        // $lt
        {
            text: 'isBefore',
            value: '$lt',
        },
        // $lte
        {
            text: 'isBeforeOrEqualTo',
            value: '$lte',
        },
    ],
    text: [
        // $eq
        {
            text: 'is',
            value: '$eq',
        },
        // $ne
        {
            text: 'isNot',
            value: '$ne',
        },
        // $null
        {
            text: 'isNull',
            value: '$null',
        },
        // $notNull
        {
            text: 'isNotNull',
            value: '$notNull',
        },
        // $contains
        {
            text: 'contains',
            value: '$contains',
        },
        // $notContains
        {
            text: 'doesNotContain',
            value: '$notContains',
        },
        // $startsWith
        {
            text: 'startsWith',
            value: '$startsWith',
        },
        // $endsWith
        {
            text: 'endsWith',
            value: '$endsWith',
        },
    ],
    number: [
        // $is
        {
            text: 'is',
            value: '$eq',
        },
        // $ne
        {
            text: 'isNot',
            value: '$ne',
        },
        // $null
        {
            text: 'isNull',
            value: '$null',
        },
        // $notNull
        {
            text: 'isNotNull',
            value: '$notNull',
        },
        // $gt
        {
            text: 'isGreaterThan',
            value: '$gt',
        },
        // $gte
        {
            text: 'isGreaterThanOrEqualTo',
            value: '$gte',
        },
        // $lt
        {
            text: 'isLessThan',
            value: '$lt',
        },
        // $lte
        {
            text: 'isLessThanOrEqualTo',
            value: '$lte',
        },
    ],
} as Record<IMember['type'], IOperator[]>;
