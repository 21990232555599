import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    // platform
    {
        key: 'installation.platform',
        title: 'Platforma',
        value: 'installation.platform',
    },
    // ref
    {
        key: 'installation.ref',
        title: 'Ref',
        value: 'installation.ref',
    },
    {
        key: 'name',
        title: 'Nome',
        value: 'name',
    },
    {
        key: 'client.nif',
        title: 'Cliente NIF',
        value: 'client.nif',
    },
    // client.name
    {
        key: 'client.name',
        title: 'Cliente Nome',
        value: 'client.name',
    },
    {
        key: 'contract.businessType',
        title: 'Tipo de negócio',
        value: 'contract.businessType',
    },
    {
        key: 'contract.businessModel',
        title: 'Modelo de negócio',
        value: 'contract.businessModel',
    },
    // country
    {
        key: 'country',
        title: 'País',
        value: 'country',
    },
] as VDataTableServer['headers'];


export const installationsHeaders = [
     // platform
     {
        key: 'platform',
        title: 'Platforma',
        value: 'platform',
    },
    // ref
    {
        key: 'ref',
        title: 'Ref',
        value: 'ref',
    },
    {
        key: 'name',
        title: 'Nome',
        value: 'name',
    },
    {
        key: 'createdAt',
        title: 'Criada em',
        value: 'createdAt',
    }
] as VDataTableServer['headers'];
