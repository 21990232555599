import { VDataTableServer } from 'vuetify/lib/components/index.mjs';

export const headers = [
    // replacementDate
    {
        key: 'replacementDate',
        title: 'Data de substituição',
        value: 'replacementDate',
        align: 'right',
    },
    // platform
    {
        key: 'oldInstallation',
        title: 'Antiga instalação',
        value: 'oldInstallation',
    },
    // newInstallation
    {
        key: 'newInstallation',
        title: 'Nova instalação',
        value: 'newInstallation',
    },

] as VDataTableServer['headers'];
