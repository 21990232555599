<template>
    <div class="tw-relative tw-h-full tw-w-full">
        <!-- loader -->
        <div
            v-if="isLoading"
            class="tw-absolute tw-h-full tw-w-full tw-transform tw-bg-gray tw-bg-opacity-10">
            <v-progress-circular
                class="tw-absolute tw-left-1/2 tw-top-1/2 tw--translate-x-1/2 tw--translate-y-1/2"
                color="primary"
                indeterminate></v-progress-circular>
        </div>
        <iframe
            v-if="currentDashboard"
            class="tw-h-full tw-w-full"
            frameborder="0"
            :src="currentDashboard.iframeUrl"></iframe>
    </div>
</template>

<script setup lang="ts">
    import { getDashboard } from '@/api/dashboards';
    import { useAlert } from '@/composables/useAlert';

    const $route = useRoute();
    const $alert = useAlert();

    const currentDashboard = ref();
    const isLoading = ref(false);

    async function init(load: boolean) {
        if (!$route.params.id) {
            return;
        }

        if(load) {
            isLoading.value = true;
        }
        
        let expiration;
        try {
            const { data } = await getDashboard($route.params.id as string);
            currentDashboard.value = data;
            expiration = (currentDashboard.value.expiration - new Date().getTime() / 1000) * 1000;

        } catch (error: any) {
            $alert.showAlert({
                text: error.message,
                type: 'error',
            })
            console.error(error);
        } finally {
            setTimeout(() => {
                isLoading.value = false;
            }, 3000);
            
            setTimeout(init, expiration - 5000, false);
            
        }
    }

    watch(
        () => $route.params.id,
        () => {
            init(true);
        },
        {
            immediate: true,
        },
    );
</script>

<style scoped></style>