<template>
    <main class="tw-flex tw-h-full tw-flex-shrink-0 tw-flex-grow tw-flex-row">
        <div
            class="tw-bg-white tw-z-10 tw-flex tw-w-full tw-shrink-0 tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-rounded-br-[2.75rem] tw-rounded-tr-[2.75rem] tw-p-4 sm:tw-max-w-lg md:tw-gap-12 xl:tw-max-w-3xl">
            <img
                alt=""
                class="tw-max-w-[28rem] tw-w-full tw-px-2"
                src="../../assets/logo.svg" />
            <router-view />
        </div>
        <div
            id="login-background"
            class="tw-z-0 tw-hidden tw-grow sm:tw-block"></div>
    </main>
</template>

<script setup lang="ts"></script>

<style scoped>
    #login-background {
        background-image: url('@/assets/images/auth/login.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        /* transform: translateX(-50%); */
        margin-left: -2.5rem;
    }
</style>
